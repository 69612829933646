import { PROVINCES } from '@dfl/location';
import { Filter, FilterType } from '@dfl/mui-admin-layout';
import { EmptyFilter, OperatorFilter, TermFilter } from '@dofleini/query-builder';
import MunicipalityFilter from 'modules/common/components/Address/MunicipalityFilter';
import { AREAS_LIST_KEY } from 'modules/settings/nomenclatures/area/constants/area.queries';
import AreaService from 'modules/settings/nomenclatures/area/services/area.service';
import { AreaCategoryService } from 'modules/settings/nomenclatures/area-category/services';

const createdATFilter: Filter = {
  filter: 'common:createdAt',
  translate: true,
  type: FilterType.DATE,
  key: 'createdAt',
  field: 'createdAt',
};

const STATE_FIELD = 'contact.address.state';
const MUNICIPALITY_FIELD = 'contact.address.municipality';

const getProvincesFilterByField = (field: string = STATE_FIELD) => ({
  filter: 'common:provinces',
  translate: true,
  type: FilterType.FIXED_LIST,
  key: 'pv',
  field,
  options: PROVINCES.map((pv) => ({
    value: pv.state,
    label: pv.name,
  })),
});

const getMunicipalityFilterByField = (field: string = MUNICIPALITY_FIELD, stateField: string = STATE_FIELD) => ({
  type: FilterType.FIXED_LIST,
  field,
  filter: 'common:municipalities',
  translate: true,
  key: 'mun',
  Component: MunicipalityFilter,
  transform: (value: any) => {
    if (Array.isArray(value)) {
      return new OperatorFilter({
        type: 'OR',
        filters: value?.map(
          (e) =>
            new OperatorFilter({
              type: 'AND',
              filters: [
                new TermFilter({ field, value: e.slice(-2) }),
                new TermFilter({ field: stateField, value: e.slice(2, -2) }),
              ],
            }),
        ),
      });
    }
    return new OperatorFilter({
      type: 'AND',
      filters: [
        new TermFilter({ field, value: value.slice(-2) }),
        new TermFilter({ field: stateField, value: value.slice(2, -2) }),
      ],
    });
  },
});

const activeStatusFilter: Filter = {
  field: 'active',
  filter: 'activeStatus',
  key: 'active',
  translate: true,
  type: FilterType.FIXED_LIST,
  transform: (value) => {
    if (Array.isArray(value)) return new EmptyFilter();
    return new TermFilter({ field: 'active', value }).toQuery();
  },
  options: [
    {
      value: 'true',
      translate: true,
      label: 'common:status.active',
    },
    {
      value: 'false',
      translate: true,
      label: 'common:status.inactive',
    },
  ],
};

const provincesFilter: Filter = getProvincesFilterByField();

const municipalitiesFilter: Filter = getMunicipalityFilterByField();

const areasFilter: Filter = {
  fetchFunc: AreaService.search,
  filter: 'audit:fields.areas',
  translate: true,
  type: FilterType.DYNAMIC_LIST,
  queryKey: AREAS_LIST_KEY,
  key: 'areas',
  field: 'areas',
};

const areaFilter: Filter = {
  fetchFunc: AreaService.search,
  filter: 'audit:fields.areas',
  translate: true,
  type: FilterType.DYNAMIC_LIST,
  queryKey: AREAS_LIST_KEY,
  key: 'areas',
  field: 'area',
};

const areaCategoryFilter: Filter = {
  fetchFunc: AreaCategoryService.search,
  filter: 'common:areaCategory',
  translate: true,
  type: FilterType.DYNAMIC_LIST,
  queryKey: 'AREA_CATEGORIES_LIST_KEY',
  key: 'area-category',
  field: 'areaCategory',
};

const updateATFilter: Filter = {
  filter: 'common:date',
  translate: true,
  type: FilterType.DATE,
  key: 'updatedAt',
  field: 'updatedAt',
};

export {
  updateATFilter,
  createdATFilter,
  areasFilter,
  provincesFilter,
  municipalitiesFilter,
  getMunicipalityFilterByField,
  getProvincesFilterByField,
  activeStatusFilter,
  areaFilter,
  areaCategoryFilter,
};
