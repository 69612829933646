import { RouteConfig } from '@dfl/react-security';
import { TEMPLATE_PERMISSIONS } from 'modules/settings/templates/main/constants/template.permissions';
import { TemplateEditPage } from 'modules/settings/templates/template-editor/pages';

const routes: RouteConfig = {
  TemplateEdit: {
    path: '/',
    exact: false,
    permissions: TEMPLATE_PERMISSIONS.TEMPLATE_VIEW,
    component: TemplateEditPage,
  },
};

export default routes;
