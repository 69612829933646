import { THEMES } from '@dfl/mui-react-common';
import { colors, createTheme } from '@mui/material';
import { iconsTheme } from 'settings/theme/icons.theme';
import { paletteDark } from 'settings/theme/palette.dark.theme';
import { paletteLight } from 'settings/theme/palette.light.theme';
import { buttonsTheme } from './buttons.theme';
import { cardsTheme } from './cards.theme';
import { checkboxTheme } from './checkbox.theme';
import { chipsTheme } from './chips.theme';
import { dialogsTheme } from './dialogs.theme';
import { inputsTheme } from './inputs.theme';
import { navbarTheme } from './navbar.theme';
import { papersTheme } from './papers.theme';
import { tableTheme } from './table.theme';
import { tabsTheme } from './tabs.theme';
import {
  typographiesTheme as typography,
  //  typographyTheme
} from './typographies.theme';

export const RED = '#d32f2f';
export const GREEN = '#4caf50';
export const WARNING = colors.deepOrange['500'];

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
    tertiary: Palette['primary'];
  }

  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
    tertiary?: PaletteOptions['primary'];
  }
}

export const common = createTheme({
  components: {
    ...navbarTheme,
    ...buttonsTheme,
    ...checkboxTheme,
    ...cardsTheme,
    ...chipsTheme,
    ...dialogsTheme,
    ...inputsTheme,
    ...papersTheme,
    ...tableTheme,
    ...tabsTheme,
    ...iconsTheme,
    // ...typographyTheme,

    MuiTypography: {
      styleOverrides: {
        h6: {
          marginBottom: '0 !important',
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        ':root, :before, :after': {
          '--primary-color': `${theme.palette.primary.main} !important`,
        },
        '.truncate': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        '#more-chip': {
          textWrap: 'nowrap',
          padding: '0 5px 0 5px',
        },
        '.smallInput': {
          input: {
            paddingTop: '8.5px !important',
            paddingBottom: '8.5px !important',
          },
        },
      }),
    },
  },
  typography,
});

const LIGHT = {
  ...common,
  palette: paletteLight,
};

const DARK = {
  ...common,
  palette: paletteDark,
};

export const THEME_SETTING = {
  [THEMES.LIGHT]: LIGHT,
  [THEMES.DARK]: DARK,
};
