import { ComponentThemeType } from './theme.types';

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    cancelled: true;
    space: true;
  }
}

export const chipsTheme: ComponentThemeType = {
  MuiChip: {
    styleOverrides: {
      root: {
        fontWeight: 600,
      },
    },
  },
};
