import { DetailsIcon, EditIcon, TrashIcon } from 'components/SvgIcons';
import { useTranslation } from 'react-i18next';

type TableMenuOptions = {
  handleEdit?: () => void;
  handleDetails?: () => void;
  handleDelete?: () => void;
};

const useTableMenuOptions = ({ handleEdit, handleDetails, handleDelete }: TableMenuOptions) => {
  const { t } = useTranslation('common');

  return [
    {
      label: t('edit'),
      icon: EditIcon,
      onClick: handleEdit,
    },
    {
      label: t('details'),
      icon: DetailsIcon,
      onClick: handleDetails,
    },
    {
      label: t('delete'),
      icon: TrashIcon,
      onClick: handleDelete,
    },
  ];
};

export default useTableMenuOptions;
