import { createSvgIcon } from '@mui/material';
const AddSvg = createSvgIcon(
  <svg width='75' height='80' viewBox='0 0 75 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M40.7533 10.6371L59.6216 15.6556C66.5759 17.5053 69.3744 22.5131 67.5345 29.3292L61.3031 52.4142C60.1043 56.8551 57.6516 59.6239 54.2132 60.5522C54.3484 59.6223 54.4179 58.6535 54.4179 57.6498V35.9017C54.4179 26.4784 48.3305 20.143 39.2397 20.143H26.5811L27.0109 18.5507C28.8507 11.7347 33.9093 8.8167 40.7533 10.6371Z'
      fill='#1C559C'
    />
    <path
      d='M15.8603 20.3047H39.1103C43.5241 20.3047 47.1706 21.8397 49.7144 24.4827C52.26 27.1275 53.7416 30.9239 53.7416 35.5165V57.2646C53.7416 61.8435 52.2603 65.6332 49.7146 68.2749C47.1707 70.9147 43.5241 72.4497 39.1103 72.4497H15.8603C11.4463 72.4497 7.79274 70.9146 5.24203 68.2744C2.68973 65.6325 1.20215 61.8428 1.20215 57.2646V35.5165C1.20215 30.9246 2.68998 27.1282 5.24226 24.4832C7.7929 21.8398 11.4464 20.3047 15.8603 20.3047Z'
      fill='#2A70D7'
      fillOpacity='0.35'
      stroke='url(#paint0_linear_1722_49864)'
      strokeWidth='1.09375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <g opacity='0.5' filter='url(#filter0_f_1722_49864)'>
      <path
        d='M54.4322 59.4374C54.507 58.7334 54.5451 58.0085 54.5451 57.2646V35.5165C54.5451 26.0932 48.4578 19.7578 39.3669 19.7578H26.9755L21.2934 40.8077C19.4478 47.6449 22.3447 52.5286 29.0992 54.3252L47.7208 59.2781C50.2462 59.9498 52.5 59.9924 54.4322 59.4374Z'
        fill='#1C559C'
      />
    </g>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M30.0675 36.8875C30.0675 36.0435 29.3833 35.3594 28.5394 35.3594C27.6954 35.3594 27.0113 36.0435 27.0113 36.8875V44.6294H19.2688C18.4249 44.6294 17.7407 45.3136 17.7407 46.1575C17.7407 47.0015 18.4249 47.6856 19.2688 47.6856H27.0113V55.4285C27.0113 56.2725 27.6954 56.9566 28.5394 56.9566C29.3833 56.9566 30.0675 56.2725 30.0675 55.4285V47.6856H37.8099C38.6538 47.6856 39.338 47.0015 39.338 46.1575C39.338 45.3136 38.6538 44.6294 37.8099 44.6294H30.0675V36.8875Z'
      fill='url(#paint1_linear_1722_49864)'
    />
    <defs>
      <filter
        id='filter0_f_1722_49864'
        x='1.06055'
        y='0.0703125'
        width='73.1719'
        height='79.4385'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='9.84375' result='effect1_foregroundBlur_1722_49864' />
      </filter>
      <linearGradient
        id='paint0_linear_1722_49864'
        x1='9.1928'
        y1='25.9602'
        x2='43.0998'
        y2='68.266'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.25' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_1722_49864'
        x1='37.8067'
        y1='39.2594'
        x2='13.7495'
        y2='40.0438'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0.2' />
      </linearGradient>
    </defs>
  </svg>,
  'AddSvg',
);

export default AddSvg;
