import React from 'react';

const ReactionTypeIcon = () => (
  <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.1'
      d='M12.5807 25.5C19.5289 25.5 25.1614 19.9036 25.1614 13C25.1614 6.09644 19.5289 0.5 12.5807 0.5C5.63258 0.5 0 6.09644 0 13C0 19.9036 5.63258 25.5 12.5807 25.5Z'
      fill='#1C559C'
    />
    <g clipPath='url(#clip0_1600_15249)'>
      <path
        d='M13.0001 18.3332C16.2217 18.3332 18.8334 15.7215 18.8334 12.4998C18.8334 9.27818 16.2217 6.6665 13.0001 6.6665C9.77842 6.6665 7.16675 9.27818 7.16675 12.4998C7.16675 15.7215 9.77842 18.3332 13.0001 18.3332Z'
        stroke='#1C559C'
        strokeWidth='{1.5}'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.6667 13.6665C10.6667 13.6665 11.5417 14.8332 13.0001 14.8332C14.4584 14.8332 15.3334 13.6665 15.3334 13.6665'
        stroke='#1C559C'
        strokeWidth='{1.5}'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.25 10.75H11.2567'
        stroke='#1C559C'
        strokeWidth='{1.5}'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.75 10.75H14.7567'
        stroke='#1C559C'
        strokeWidth='{1.5}'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1600_15249'>
        <rect width='14' height='14' fill='white' transform='translate(6 5.5)' />
      </clipPath>
    </defs>
  </svg>
);

export default ReactionTypeIcon;
