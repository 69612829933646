import { ComponentThemeType } from './theme.types';

export const dialogsTheme: ComponentThemeType = {
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ theme: { palette } }) => ({
        padding: '10px 30px',
        marginBottom: '20px',
        borderBottom: `1px solid ${palette.primary.light} `,

        fontSize: '15px',

        '.MuiIconButton-root': {
          display: 'none',
        },
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '20px 24px !important',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: ' 20px 24px ',
      },
    },
  },
};
