import React from 'react';
import { FlexBox } from '@dfl/mui-react-common';
import CircleIcon from '@mui/icons-material/Circle';
import { Box, Chip, List, ListItemButton, ListItemText } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { TemplateSidebarItem } from './TemplateEditorSideBar';

export const WhiteDot = () => <CircleIcon sx={{ color: 'white', fontSize: '7px', mr: 1 }} />;

type TemplateEditorSidebarBodyProps = {
  menu: TemplateSidebarItem[];
  onItemClick?: (item: TemplateSidebarItem) => void;
};
// <Icon fontSize='small' sx={{ mr: 1, color: 'white' }} />
export const SideBarListItem = ({ text, active, onClick, className, count, icon, boldFont }: TemplateSidebarItem) => (
  <ListItemButton
    className={`hover:bg-gray-700 cursor-pointer ${active ? 'bg-gray-700' : ''} ${className as string}`}
    onClick={onClick}
  >
    {icon}
    <ListItemText primaryTypographyProps={{ sx: { color: 'white', fontWeight: active || boldFont ? 500 : 300 } }}>
      <FlexBox justifyContent={'space-between'}>
        <>{text}</>
        {Boolean(count) && (
          <Chip
            label={count}
            size={'small'}
            sx={{ fontSize: '12px', color: 'white', backgroundColor: 'primary.main' }}
          />
        )}
      </FlexBox>
    </ListItemText>
  </ListItemButton>
);

const TemplateEditorSidebarBody = ({ menu, onItemClick }: TemplateEditorSidebarBodyProps) => {
  const [params] = useSearchParams();

  return (
    <Box className={'py-2'}>
      <List>
        {menu?.map((element, idx) => (
          <React.Fragment key={idx}>
            <SideBarListItem
              {...element}
              active={
                params.get('areaId') === element.params?.areaId ||
                (element.params?.intro && element.params?.intro === Boolean(params.get('intro')))
              }
              onClick={() => {
                onItemClick?.(element);
              }}
            />
            <List>
              {element.items?.map((section) => (
                <React.Fragment key={section.text}>
                  <SideBarListItem
                    text={section.text}
                    active={params.get('areaCategoryId') === section.params?.areaCategoryId}
                    count={section.count}
                    onClick={() => {
                      onItemClick?.(section);
                    }}
                    className={'pl-10'}
                  />
                </React.Fragment>
              ))}
            </List>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default TemplateEditorSidebarBody;
