import { createSvgIcon } from '@mui/material';

const ClientIcon = createSvgIcon(
  <svg width='18' height='20' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.5 10C10.9853 10 13 7.98528 13 5.5C13 3.01472 10.9853 1 8.5 1C6.01472 1 4 3.01472 4 5.5C4 7.98528 6.01472 10 8.5 10Z'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15 16C15 14.4087 14.2625 12.8826 12.9497 11.7574C11.637 10.6321 9.85652 10 8 10C6.14348 10 4.36301 10.6321 3.05025 11.7574C1.7375 12.8826 1 14.4087 1 16'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'ClientIcon',
);

export default ClientIcon;
