import { CellAlign, HeadCell } from '@dfl/mui-admin-layout';
import { renderTagList } from '@dfl/mui-react-common';
import { Typography } from '@mui/material';
import { createdATColumn } from 'modules/common/constants';
import { AreaRowActions } from 'modules/settings/nomenclatures/area/components/AreaRowActions';
import { AREA_PERMISSIONS } from 'modules/settings/nomenclatures/area/constants/area.permissions';
import { IArea } from 'modules/settings/nomenclatures/area/interfaces';
import i18n from 'settings/i18n';

export const areaNameColumn: HeadCell<IArea> = {
  field: 'name',
  headerName: 'area:fields.name',
  disablePadding: false,
  width: 200,
  renderCell: (name: string, data: IArea) => (
    <Typography className='line-clamp-2' variant='body1' key={data._id}>
      {name}
    </Typography>
  ),
};

export const areaCategoryColumn: HeadCell<IArea> = {
  field: 'name',
  headerName: 'area:fields.areaCategory',
  disablePadding: false,
  width: 200,
  renderCell: (name: string, data: IArea) =>
    renderTagList(data?.categoryArea?.map((category) => category?.name || category) || [], 2),
};

export const areaTypeColumn: HeadCell<IArea> = {
  field: 'type',
  headerName: 'area:fields.type',
  renderCell: (name: string, data: IArea) => i18n.t(`area:typeNames.${data.type}`),
};

export const areaDescriptionColumn: HeadCell<IArea> = {
  field: 'description',
  headerName: 'area:fields.description',
  renderCell: (description: string, data: IArea) => (
    <Typography className='line-clamp-2' variant='body2' key={data._id}>
      {description}
    </Typography>
  ),
};

export const areaActionsColumn: HeadCell<IArea> = {
  field: 'actions',
  sortable: false,
  width: 100,
  permissions: AREA_PERMISSIONS.AREA_WRITE,
  headerName: 'common:actions',
  disablePadding: true,
  component: AreaRowActions,
  align: CellAlign.CENTER,
};

export const areaColumns: Array<HeadCell<any>> = [
  areaNameColumn,
  areaDescriptionColumn,
  areaTypeColumn,
  areaCategoryColumn,
  createdATColumn,
  areaActionsColumn,
];
