import { ReactNode } from 'react';
import { IconButton } from '@dfl/mui-react-common';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ClickAwayListener, Paper, Tooltip } from '@mui/material';

type Props = {
  children: ReactNode;
  disabled?: boolean;
  onClose: () => void;
  open?: boolean;
  setOpenTooltip: (value: boolean) => void;
};

const ActionTableMenu = ({ children, disabled, onClose, open, setOpenTooltip }: Props) => {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <div>
        <Tooltip
          open={open}
          title={
            <Paper
              elevation={9}
              sx={{
                bgcolor: 'background.paper',
                py: 1,
              }}
            >
              {children}
            </Paper>
          }
          arrow
          placement='left'
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                bgcolor: 'transparent',
                padding: 0,
                margin: 0,
              },
              '& .MuiTooltip-arrow': {
                color: 'background.paper',
                boxShadow: 'revert',
                fontSize: '1.4rem',
              },
            },
          }}
        >
          <IconButton
            disabled={disabled}
            tooltip={'' /* t('options') */}
            aria-label='more'
            id='long-button'
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup='true'
            // onClick={open}
            onClick={() => {
              setOpenTooltip(!open);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default ActionTableMenu;
