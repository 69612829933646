import { memo } from 'react';
import { useToggle } from '@dfl/hook-utils';
import { ConfirmDialog } from '@dfl/mui-admin-layout';
import { useParamsLink } from '@dfl/react-security';
import { ListItemIcon, MenuItem, Typography } from '@mui/material';
import useRowAction from 'hooks/useRowAction';
import useTableMenuOptions from 'hooks/useTableMenuOptions';
import ActionTableMenu from 'modules/common/components/ActionTableMenu/ActionTableMenu';
import { useDeleteTemplate } from 'modules/settings/templates/main/hooks/useDeleteTemplate';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

type UserStatusProps = {
  rowId: string;
};

const TemplateRowActions = ({ rowId }: UserStatusProps) => {
  const { t } = useTranslation('common');
  const { isOpen, onClose, onOpen } = useToggle();
  const navigate = useNavigate();
  const handleEdit = useParamsLink({ edit: rowId });
  const { mutate, isLoading, error } = useDeleteTemplate(rowId, onClose);
  const options = useTableMenuOptions({
    handleEdit,
    handleDetails: () => {
      navigate(`/settings/templates/${rowId}/edit`);
    },
    handleDelete: onOpen,
  });

  const { openTooltip, setOpenTooltip, handleAction } = useRowAction();

  return (
    <>
      <ConfirmDialog
        confirmButtonText={t('accept')}
        title={t('deleteConfirmation.title')}
        onConfirm={mutate}
        confirmationMessage={t('deleteConfirmation.confirmation')}
        isLoading={isLoading}
        error={error}
        onClose={onClose}
        open={isOpen}
      />
      <ActionTableMenu onClose={handleAction} open={openTooltip} setOpenTooltip={setOpenTooltip}>
        {options.map((option, idx) => (
          <MenuItem
            onClick={() => {
              handleAction(option.onClick);
            }}
            key={idx}
          >
            <ListItemIcon>
              <option.icon />
            </ListItemIcon>
            <Typography color='secondary'>{option.label}</Typography>
          </MenuItem>
        ))}
      </ActionTableMenu>
    </>
  );
};

export default memo(TemplateRowActions);
