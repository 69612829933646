import { ApiClientService } from '@dfl/react-security';
import { IArea } from 'modules/settings/nomenclatures/area/interfaces';
import { IBiensecModule, PeriodicityEnum } from 'modules/settings/nomenclatures/module/interfaces';
import { ITemplate, ITemplateField } from 'modules/settings/templates/main/interfaces';
import { ITemplateItemSettings, ItemAnswerSettings } from 'modules/settings/templates/main/interfaces/IItemConfigs';
import EntityApiServicePopulate from 'utils/EntityApiServicePopulate';

const resolve = <T>(data: T) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, 2000);
  });
};

class TemplateService extends EntityApiServicePopulate<ITemplate> {
  duplicate = (templateId: string) => {
    return this.handleResponse(ApiClientService.post(this.getPath(`/${templateId}/duplicate`), {}));
  };

  addField = (templateId: string, generalInfo: ITemplateField[], field: ITemplateField) => {
    // TODO: validate duplicates
    generalInfo.push(field);
    return this.update(templateId, { _id: templateId, generalInfo });
  };

  removeField = (templateId: string, generalInfo: ITemplateField[], fieldId: number) => {
    generalInfo = generalInfo.filter((f, index) => index !== fieldId) || [];
    return this.update(templateId, { _id: templateId, generalInfo });
  };

  updateField = (templateId: string, generalInfo: ITemplateField[], name: number, newData: Partial<ITemplateField>) => {
    const field = generalInfo[name];
    if (!field) {
      throw new Error('field not found');
    }
    Object.assign(field, newData);
    return this.update(templateId, { _id: templateId, generalInfo });
  };

  removeArea = (templateId: string, operationalareaId: string) => {
    return resolve(true) as Promise<boolean>;
  };

  addArea = (templateId: string, area: IArea) => {
    return resolve(true) as Promise<boolean>;
  };

  addResponse = (
    templateId: string,
    sectionId: string,
    subSectionId: string,
    itemId: string,
    answer: ItemAnswerSettings,
  ) => {
    return resolve(answer) as Promise<any>;
  };

  addConfigItem = (
    templateId: string,
    sectionId: string,
    subSectionId: string,
    itemId: string,
    config: ITemplateItemSettings,
  ) => {
    return resolve(config) as Promise<any>;
  };

  getModulesForTemplate = (templateId: string): Promise<IBiensecModule[]> => {
    const modules: IBiensecModule[] = [
      {
        _id: 'mod1',
        name: 'Module 1',
        description: 'Module 1 Description',
        createdAt: new Date(),
        active: true,
        periodicity: PeriodicityEnum.MONTHLY,
      },
      {
        _id: 'mod2',
        name: 'Module 2',
        description: 'Module 2 Description',
        createdAt: new Date(),
        active: false,
        periodicity: PeriodicityEnum.MONTHLY,
      },
    ];

    return Promise.resolve(modules);
  };
}

export default new TemplateService('/ms-core/api/template');
