import { createSvgIcon } from '@mui/material';

const SettingsIcon = createSvgIcon(
  <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.625 12.6667V6.33334C16.6247 6.05568 16.5514 5.78298 16.4125 5.54259C16.2735 5.3022 16.0738 5.10258 15.8333 4.96375L10.2917 1.79709C10.051 1.65812 9.77793 1.58496 9.5 1.58496C9.22207 1.58496 8.94903 1.65812 8.70833 1.79709L3.16667 4.96375C2.92621 5.10258 2.72648 5.3022 2.58753 5.54259C2.44858 5.78298 2.37528 6.05568 2.375 6.33334V12.6667C2.37528 12.9443 2.44858 13.217 2.58753 13.4574C2.72648 13.6978 2.92621 13.8974 3.16667 14.0363L8.70833 17.2029C8.94903 17.3419 9.22207 17.415 9.5 17.415C9.77793 17.415 10.051 17.3419 10.2917 17.2029L15.8333 14.0363C16.0738 13.8974 16.2735 13.6978 16.4125 13.4574C16.5514 13.217 16.6247 12.9443 16.625 12.6667Z'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.49992 12.6667C11.2488 12.6667 12.6666 11.2489 12.6666 9.50001C12.6666 7.75111 11.2488 6.33334 9.49992 6.33334C7.75102 6.33334 6.33325 7.75111 6.33325 9.50001C6.33325 11.2489 7.75102 12.6667 9.49992 12.6667Z'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'SettingsIcon',
);

export default SettingsIcon;
