import { ISortable } from 'modules/common/interfaces';
import { SURVEY_QUIZ_FIELDS } from 'modules/survey/management/constants';

export enum FieldType {
  TEXT = 'string',
  NUMERIC = 'number',
  DATE = 'date',
  ARRAY = 'array',
  SELECT = 'select',
  REACTION = 'reaction',
  YESNO = 'string',
  NPS = 'nps',
}

export interface ITemplateField extends ISortable {
  id?: any;
  fieldName: string | SURVEY_QUIZ_FIELDS;
  fieldType: FieldType;
}
