import { ComponentThemeType } from './theme.types';

export const tableTheme: ComponentThemeType = {
  MuiTableContainer: {
    styleOverrides: {
      root: {
        borderRadius: '5px',
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: ({ theme: { palette } }) => ({
        // background: palette.mode === 'light' ? palette.grey[100] : grey['800'],
        textTransform: 'uppercase',

        '.MuiTableCell-root,.MuiTableSortLabel-root': {},
      }),
    },
  },
  // MuiTableBody: {
  //   styleOverrides: {
  //     root: ({ theme: { palette } }) => ({
  //       '& tr:nth-child(even)': {
  //         background: palette.mode === 'light' ? palette.grey[100] : grey['800'],
  //       },
  //     }),
  //   },
  // },
  MuiTableRow: {
    styleOverrides: {
      root: ({ theme: { palette } }) => ({
        '&.Mui-selected': {
          backgroundColor: ` ${palette.background.default} !important `,
        },
      }),
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: ({ theme: { palette } }) => ({
        // color: '#1C2333',
        color: ` ${palette.neutral.main} !important `,
        fontSize: '14px',
        fontWeight: 600,
      }),
    },
  },
};
