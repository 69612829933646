import { memo } from 'react';
import { ChildrenProps, DateSettingsProvider } from '@dfl/mui-react-common';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import { useTranslation } from 'react-i18next';

type DateProviderProps = ChildrenProps;

export const localeMap = {
  es: esLocale,
  en: enLocale,
};
export const locales = ['en', 'es'] as const;

const DateProvider = ({ children }: DateProviderProps) => {
  const {
    i18n: { language },
  } = useTranslation();
  return (
    <DateSettingsProvider localeMap={localeMap} locale={language}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        // @ts-ignore
        adapterLocale={localeMap[language] || esLocale}
      >
        {children}
      </LocalizationProvider>
    </DateSettingsProvider>
  );
};

export default memo(DateProvider);
