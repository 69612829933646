import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AREAS_LIST_KEY } from 'modules/settings/nomenclatures/area/constants';
import { AreaService } from 'modules/settings/nomenclatures/area/services';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export const useDeleteArea = (id: string, onClose: () => void) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('area');
  return useMutation(() => AreaService.delete(id), {
    onSuccess: (data) => {
      toast.success(t('successDeleted'));
      onClose?.();
      queryClient.invalidateQueries([AREAS_LIST_KEY]);
      queryClient.invalidateQueries([id]);
    },
  });
};
