import { createSvgIcon } from '@mui/material';

const DetailsIcon = createSvgIcon(
  <svg width='17' height='24' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.7778 1H2.22222C1.54721 1 1 1.54721 1 2.22222V10.7778C1 11.4528 1.54721 12 2.22222 12H10.7778C11.4528 12 12 11.4528 12 10.7778V2.22222C12 1.54721 11.4528 1 10.7778 1Z'
      stroke='#64748B'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.92871 4.14282H8.85728'
      stroke='#64748B'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M4.14307 6.5H8.07164' stroke='#64748B' strokeWidth={1.5} strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M5.71436 8.85718H8.85721'
      stroke='#64748B'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'DetailsIcon',
);

export default DetailsIcon;
