import { createSvgIcon } from '@mui/material';

const SubSectionIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20 4H4C3.44772 4 3 4.44772 3 5V10C3 10.5523 3.44772 11 4 11H20C20.5523 11 21 10.5523 21 10V5C21 4.44772 20.5523 4 20 4Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20 14H4C3.44772 14 3 14.4477 3 15V17.5C3 18.0523 3.44772 18.5 4 18.5H20C20.5523 18.5 21 18.0523 21 17.5V15C21 14.4477 20.5523 14 20 14Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'SubSectionIcon',
);

export default SubSectionIcon;
