import { THEMES } from '@dfl/mui-react-common';
import { grey } from '@mui/material/colors';

export const paletteDark = {
  mode: THEMES.DARK,
  sidebar: {
    background: 'neutral.light',
    color: '#f3f4f9',
    activeColor: 'primary.main',
    active: 'neutral.main',
  },
  spaceSelector: '#f3f4f9',
  primary: {
    dark: '#1A237E',
    main: '#3F51B5',
    light: '#C5CAE9',
  },
  secondary: {
    ...grey,
    dark: grey['900'],
    main: grey['700'],
    light: grey['500'],
    contrastText: '#213053',
  },
  neutral: {
    main: '#213053',
    light: '#3C4E76',
    dark: '#553089',
  },
  tertiary: {
    main: '#3C4E76',
    light: '#f3f4f9',
    contrastText: '#3C4E76',
  },
  background: {
    default: '#213053',
    paper: '#3C4E76',
  },
  formLabel: '#000000de',
  link: '#1a0dab',
  success: {
    main: '#47CE81',
    light: '#EAFAF1',
  },
  warning: {
    main: '#FFAE34',
    light: '#FFF3E0',
  },
  error: {
    main: '#b50f0f',
    light: '#f6e3e3'
  }
};
