import { ComponentThemeType } from './theme.types';

export const tabsTheme: ComponentThemeType = {
  MuiTabs: {
    styleOverrides: {
      flexContainer: {
        '.MuiButtonBase-root': {
          minWidth: 20,
          textTransform: 'none',
        },
      },
    },
  },
};
