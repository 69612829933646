import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { TEMPLATES_ONE_KEY } from 'modules/settings/templates/main/constants';
import { ITemplate } from 'modules/settings/templates/main/interfaces';
import { TemplateService } from 'modules/settings/templates/main/services';

export const useFindOneTemplate = (id: string | null) => {
  const fetch = useCallback(() => TemplateService.getOne(id as string), [id]);
  return useQuery<ITemplate>([id, TEMPLATES_ONE_KEY], fetch, { enabled: !!id });
};
