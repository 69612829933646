const NumberTypeIcon = () => (
  <svg width='27' height='25' viewBox='0 0 27 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.1'
      d='M13.5339 25C20.8174 25 26.7218 19.4036 26.7218 12.5C26.7218 5.59644 20.8174 0 13.5339 0C6.25039 0 0.345947 5.59644 0.345947 12.5C0.345947 19.4036 6.25039 25 13.5339 25Z'
      fill='#1C559C'
    />
    <path
      d='M8.75424 15.775H7.1316V11.163H5.51001V9.62598H8.75424V15.775ZM15.7829 14.238H12.5387V13.213H14.7015C14.988 13.2122 15.2626 13.1039 15.4652 12.9119C15.6678 12.7198 15.782 12.4596 15.7829 12.188V10.651C15.782 10.3794 15.6678 10.1191 15.4652 9.92707C15.2626 9.73501 14.988 9.62677 14.7015 9.62598H10.9171V11.163H14.1613V12.188H11.9985C11.7119 12.1888 11.4373 12.297 11.2347 12.4891C11.0321 12.6811 10.9179 12.9414 10.9171 13.213V15.775H15.7829V14.238ZM22.2703 14.75V10.65C22.2692 10.3785 22.1549 10.1185 21.9523 9.92671C21.7497 9.73487 21.4752 9.62677 21.1889 9.62598H17.4045V11.163H20.6487V12.188H18.4869V13.213H20.6498V14.238H17.4045V15.775H21.1889C21.4752 15.7742 21.7497 15.6661 21.9523 15.4742C22.1549 15.2824 22.2692 15.0224 22.2703 14.751V14.75Z'
      fill='#1C559C'
    />
  </svg>
);

export default NumberTypeIcon;
