const SelectionTypeIcon = () => (
  <svg width='28' height='25' viewBox='0 0 28 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.1'
      d='M14.1425 25C21.426 25 27.3305 19.4036 27.3305 12.5C27.3305 5.59644 21.426 0 14.1425 0C6.85903 0 0.95459 5.59644 0.95459 12.5C0.95459 19.4036 6.85903 25 14.1425 25Z'
      fill='#1C559C'
    />
    <path
      d='M14.3198 5.35632C12.9494 5.35632 11.6097 5.75406 10.4703 6.49924C9.33083 7.24442 8.44273 8.30357 7.9183 9.54276C7.39387 10.7819 7.25666 12.1455 7.52401 13.461C7.79136 14.7765 8.45127 15.9849 9.4203 16.9334C10.3893 17.8818 11.6239 18.5277 12.968 18.7893C14.3121 19.051 15.7052 18.9167 16.9713 18.4034C18.2374 17.8901 19.3196 17.0209 20.0809 15.9057C20.8423 14.7904 21.2486 13.4793 21.2486 12.138C21.2479 10.3396 20.5177 8.61507 19.2184 7.34341C17.9192 6.07175 16.1572 5.35703 14.3198 5.35632ZM14.3198 17.5633C13.2234 17.5633 12.1517 17.2451 11.2402 16.649C10.3286 16.0528 9.61814 15.2055 9.19859 14.2142C8.77905 13.2228 8.66928 12.132 8.88316 11.0796C9.09704 10.0271 9.62497 9.06045 10.4002 8.3017C11.1754 7.54295 12.1631 7.02624 13.2384 6.8169C14.3136 6.60757 15.4281 6.715 16.441 7.12564C17.4539 7.53627 18.3196 8.23164 18.9287 9.12384C19.5378 10.016 19.8629 11.065 19.8629 12.138C19.8609 13.5763 19.2763 14.9552 18.2372 15.9722C17.1981 16.9892 15.7893 17.5614 14.3198 17.5633ZM17.5044 9.14034L12.934 13.6092L11.1397 11.8575L10.1624 12.8157L12.934 15.5284L18.4771 10.103L17.5044 9.14034Z'
      fill='#1C559C'
    />
  </svg>
);

export default SelectionTypeIcon;
