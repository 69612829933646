import { IArea } from 'modules/settings/nomenclatures/area/interfaces';
import { IBiensecModule } from 'modules/settings/nomenclatures/module/interfaces';
import { ITemplateField } from 'modules/settings/templates/main/interfaces/ITemplateField';
import { ITemplateSection } from 'modules/settings/templates/main/interfaces/ITemplateSection';
import { ITemplateItem } from './ITemplateItem';

export enum ServiceType {
  AUDIT = 'AUDIT',
  SURVEY = 'SURVEY',
  BIENSEC = 'BIENSEC',
}

export interface ITemplate {
  _id?: string;
  name: string;
  description?: string;
  serviceType: ServiceType;
  module?: IBiensecModule | null;
  areas?: string[] | IArea[] | null;
  createdAt?: Date;
  updatedAt?: Date;
  active?: boolean;
  items?: ITemplateItem[];
  sections: ITemplateSection[];
  generalInfo: ITemplateField[];
}

export enum OpenModal {
  ITEM_CONFIG = 'ITEM_CONFIG',
  ANSWER_TYPE = ' ANSWER_TYPE',
}
