import { ApiClientService, EntityApiService, RequestConfig, SearchResponseType } from '@dfl/react-security';

class EntityApiServicePopulate<T> extends EntityApiService<T> {
  searchPopulated = (params?: any, config?: RequestConfig | undefined): Promise<SearchResponseType<T>> => {
    params.populate = true;
    return this.search(params, config);
  };

  getOnePopulated = (id: string, config?: RequestConfig | undefined): Promise<T> => {
    return this.handleResponse(ApiClientService.get(this.getPath(`/${id}?populate=true`), config));
  };
}

export default EntityApiServicePopulate;
