import { createSvgIcon } from '@mui/material';

const TrashIcon = createSvgIcon(
  <svg width='21' height='24' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.33301 6.10718H13.9997'
      stroke='#64748B'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.6665 6.10718V14.4197C12.6665 15.0134 12.0951 15.6072 11.5236 15.6072H5.80936C5.23793 15.6072 4.6665 15.0134 4.6665 14.4197V6.10718'
      stroke='#64748B'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.99951 6.10711V4.74997C5.99951 4.07139 6.66618 3.39282 7.33284 3.39282H9.99951C10.6662 3.39282 11.3328 4.07139 11.3328 4.74997V6.10711'
      stroke='#64748B'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'TrashIcon',
);

export default TrashIcon;
