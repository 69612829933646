import { memo, Suspense } from 'react';
import { PageLoader } from '@dfl/mui-react-common';
import { RouteLoader } from '@dfl/react-security';
import TemplateEditorLayout from 'layouts/TemplateEditorLayout';
import { UserControl } from 'modules/authentication/components/UserControl';
import routes from 'modules/settings/templates/template-editor/routes';

const TemplateEditorApp = () => {
  return (
    <UserControl>
      <TemplateEditorLayout>
        <Suspense fallback={<PageLoader />}>
          <RouteLoader routes={routes} notfoundRedirect={'/settings/template-editor'} />
        </Suspense>
      </TemplateEditorLayout>
    </UserControl>
  );
};

export default memo(TemplateEditorApp);
