import { useFindOneTemplate } from 'modules/settings/templates/main/hooks/useFindOneTemplate';
import { useParams } from 'react-router';

const useTemplateEditorNavbar = () => {
  const { id } = useParams();
  const { data, isLoading } = useFindOneTemplate(id || '');

  return {
    template: data,
    isLoading,
  };
};

export default useTemplateEditorNavbar;
