import { createSvgIcon } from '@mui/material';

const NomenclatureIcon = createSvgIcon(
  <svg width='22' height='22' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.2875 0.506256H2.7125C1.75625 0.506256 0.96875 1.29376 0.96875 2.25001V15.75C0.96875 16.7063 1.75625 17.5219 2.74063 17.5219H13.3156C14.2719 17.5219 15.0875 16.7344 15.0875 15.75V2.25001C15.0313 1.29376 14.2438 0.506256 13.2875 0.506256ZM13.7656 15.75C13.7656 16.0313 13.5406 16.2563 13.2594 16.2563H2.7125C2.43125 16.2563 2.20625 16.0313 2.20625 15.75V2.25001C2.20625 1.96876 2.43125 1.74376 2.7125 1.74376H13.2875C13.5688 1.74376 13.7938 1.96876 13.7938 2.25001V15.75H13.7656Z'
      fill='currentColor'
    />
    <path
      d='M11.7969 2.61563H8.73123C8.22498 2.61563 7.8031 3.03751 7.8031 3.54376V7.25626C7.8031 7.76251 8.22498 8.18438 8.73123 8.18438H11.7969C12.3031 8.18438 12.725 7.76251 12.725 7.25626V3.51563C12.6969 3.03751 12.3031 2.61563 11.7969 2.61563ZM11.4312 6.89063H9.06873V3.88126H11.4312V6.89063Z'
      fill='currentColor'
    />
    <path
      d='M3.9781 4.35938H6.03123C6.36873 4.35938 6.6781 4.07812 6.6781 3.7125C6.6781 3.34687 6.39685 3.09375 6.03123 3.09375H3.94998C3.61248 3.09375 3.3031 3.375 3.3031 3.74063C3.3031 4.10625 3.61248 4.35938 3.9781 4.35938Z'
      fill='currentColor'
    />
    <path
      d='M3.9781 7.93126H6.03123C6.36873 7.93126 6.6781 7.65001 6.6781 7.28439C6.6781 6.91876 6.39685 6.63751 6.03123 6.63751H3.94998C3.61248 6.63751 3.3031 6.91876 3.3031 7.28439C3.3031 7.65001 3.61248 7.93126 3.9781 7.93126Z'
      fill='currentColor'
    />
    <path
      d='M12.0782 10.2375H3.97817C3.64067 10.2375 3.3313 10.5188 3.3313 10.8844C3.3313 11.25 3.61255 11.5313 3.97817 11.5313H12.0782C12.4157 11.5313 12.725 11.25 12.725 10.8844C12.725 10.5188 12.4157 10.2375 12.0782 10.2375Z'
      fill='currentColor'
    />
    <path
      d='M12.0782 13.8094H3.97817C3.64067 13.8094 3.3313 14.0906 3.3313 14.4563C3.3313 14.8219 3.61255 15.1031 3.97817 15.1031H12.0782C12.4157 15.1031 12.725 14.8219 12.725 14.4563C12.725 14.0906 12.4157 13.8094 12.0782 13.8094Z'
      fill='currentColor'
    />
  </svg>,
  'NomenclatureIcon',
);

export default NomenclatureIcon;
