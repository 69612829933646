import { ComponentThemeType } from './theme.types';

export const buttonsTheme: ComponentThemeType = {
  MuiButtonBase: {
    styleOverrides: {
      root: ({ theme: { palette } }) => ({
        color: palette.neutral.main,
      }),
    },
  },
  MuiButton: {
    styleOverrides: {
      outlinedSizeMedium: {
        borderRadius: 8,
        '& > span': {
          color: 'black',
        },
      },
      root: ({ theme: { palette } }) => ({
        textTransform: 'none',
        // fontWeight: 'bold',
        '.MuiChip-root': {
          height: '17px',
        },
        boxShadow: 'none',
        '.MuiButton-startIcon': {
          color: 'inherit',
        },
        '& > span': {
          color: palette.tertiary.light,
        },
      }),
    },
    variants: [
      {
        props: { variant: 'contained' },
        style: ({ theme: { palette } }) => ({
          color: palette.tertiary.main,
          backgroundColor: palette.primary.main,
        }),
      },
      {
        props: { variant: 'outlined' },
        style: ({ theme: { palette } }) => ({
          color: 'black !important',
          // backgroundColor: palette.primary.main,
        }),
      },
      {
        props: { variant: 'contained', color: 'success' },
        style: ({ theme: { palette } }) => ({
          backgroundColor: palette.success.main,
          '&:hover': {
            color: palette.success.main,
            backgroundColor: palette.success.light,
          },
        }),
      },
      {
        props: { variant: 'contained', color: 'error' },
        style: ({ theme: { palette } }) => ({
          backgroundColor: palette.error.main,
          '&:hover': {
            backgroundColor: palette.error.light,
          },
        }),
      },
      {
        props: { variant: 'text', color: 'secondary' },
        style: ({ theme: { palette } }) => ({
          backgroundColor: palette.grey[200],
          color: 'black',
        }),
      },

      {
        props: { variant: 'outlined', color: 'error' },
        style: ({ theme: { palette } }) => ({
          backgroundColor: 'transparent',
          borderRadius: 5,
          border: '1px solid',
          borderColor: palette.error.main,
        }),
      },
      {
        props: { variant: 'outlined', color: 'info' },
        style: ({ theme: { palette } }) => ({
          backgroundColor: 'transparent',
          borderRadius: 5,
          border: '1px solid',
          borderColor: palette.info.main,
        }),
      },
      {
        props: { variant: 'outlined', color: 'secondary' },
        style: ({ theme: { palette } }) => ({
          backgroundColor: 'transparent',
          borderRadius: 5,
          border: '1px solid',
          borderColor: palette.secondary.main,
        }),
      },
      {
        props: { variant: 'outlined', color: 'success' },
        style: ({ theme: { palette } }) => ({
          backgroundColor: 'transparent',
          borderRadius: 5,
          border: '1px solid',
          borderColor: palette.success.main,
        }),
      },
      {
        props: { variant: 'outlined', color: 'warning' },
        style: ({ theme: { palette } }) => ({
          backgroundColor: 'transparent',
          borderRadius: 5,
          border: '1px solid',
          borderColor: palette.warning.main,
        }),
      },
    ],
  },
  MuiIconButton: {
    variants: [
      {
        props: { size: 'large', color: 'primary' },
        style: ({ theme: { palette } }) => ({
          border: `1px solid ${palette.grey[200]}`,
        }),
      },
    ],
  },
};
