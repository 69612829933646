export const CheckBoxIconChecked = () => {
  return (
    <svg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1343_1550)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18 12C14.6863 12 12 14.6863 12 18V24C12 27.3137 14.6863 30 18 30H24C27.3137 30 30 27.3137 30 24V18C30 14.6863 27.3137 12 24 12H18ZM25.7179 18.6961C26.1024 18.2997 26.0926 17.6666 25.6961 17.2821C25.2997 16.8976 24.6666 16.9074 24.2821 17.3039L19.6667 22.0635L17.7179 20.0539C17.3334 19.6574 16.7003 19.6476 16.3039 20.0321C15.9074 20.4166 15.8976 21.0497 16.2821 21.4461L18.9488 24.1961C19.1371 24.3904 19.3961 24.5 19.6667 24.5C19.9372 24.5 20.1962 24.3904 20.3846 24.1961L25.7179 18.6961Z'
          fill='#2A70D7'
        />
      </g>
      <defs>
        <clipPath id='clip0_1343_1550'>
          <rect width='42' height='42' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CheckBoxIconUnChecked = () => {
  return (
    <svg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='13' y='13' width='16' height='16' rx='5' stroke='#6C737F' strokeWidth={2} />
    </svg>
  );
};
