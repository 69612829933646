import { ENTITY } from 'constants/entity.enum';
import { useTableRequest } from '@dfl/mui-admin-layout';
import { TermFilter } from '@dofleini/query-builder';
import { useQuery } from '@tanstack/react-query';
import { AREAS_LIST_KEY } from 'modules/settings/nomenclatures/area/constants';
import { AreaService } from 'modules/settings/nomenclatures/area/services';

type FindAreaParams = {
  entity?: ENTITY;
  entityId?: string;
};

export const useFindAreas = (filter?: Partial<TermFilter>, urlParams?: FindAreaParams, getAll?: boolean) => {
  const { fetch, queryKey } = useTableRequest((params) => {
    if (getAll) {
      params.size = 200;
    }
    return AreaService.searchPopulated(params, { params: urlParams });
  }, filter);

  const query = useQuery([AREAS_LIST_KEY, queryKey, urlParams], fetch);

  return { ...query };
};
