import { createSvgIcon } from '@mui/material';

const BiensecIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1080 1080'>
    <path
      fill='currentColor'
      d='M9.484 16.591c2.598-1.115 4.151-4.333 4.456-7.168C10.1 4.32 5.266-.123 1.176-5.053L-8.54-15.68c-1.84-1.897-1.46-2.227-5.324-2.104-.144 2.127-.044 4.566-.06 6.746-.035 4.464 3.795 6.172 9.25 12.615C-2.898 3.677 8.087 15.9 9.483 16.59zm-23.424 1.085l18.664.173c-.361-1.38-7.467-9.171-9.019-10.795-1.587-1.663-8.117-9.42-9.655-10l.01 20.622zM9.045-1.88c3.754-2.139 4.495-8.184 2.016-12.257-2.878-4.73-9.422-3.595-16.218-3.59L9.045-1.88z'
      transform='scale(20) translate(20, 30)'
    />
  </svg>,
  'BiensecIcon',
);

export default BiensecIcon;
