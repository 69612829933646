import { memo, useCallback, useMemo } from 'react';
import { Navbar as AdminNavbar } from '@dfl/mui-admin-layout';
import { SkeletonList } from '@dfl/mui-react-common';
import { useSearchParamsChange } from '@dfl/react-security';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { AppBar, Box, Button, IconButton, Toolbar, Typography } from '@mui/material';
import { formatDistance } from 'date-fns';
import es from 'date-fns/locale/es';
import useTemplateEditorNavbar from 'modules/settings/templates/template-editor/hooks/useTemplateEditorNavbar';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { isMobile } from 'utils/index';

interface TemplateEditorNavBarProps {
  onOpenSidebar: () => void;
}

const TemplateEditorNavBar = ({ onOpenSidebar }: TemplateEditorNavBarProps) => {
  const { t } = useTranslation('templates');
  const navigate = useNavigate();
  const { template, isLoading } = useTemplateEditorNavbar();
  const [searchParams] = useSearchParams();
  const preview = searchParams.get('preview');
  const { update: updateSearchParams, removeField } = useSearchParamsChange();

  const backNavigationHandler = useCallback(() => {
    navigate('/settings/templates');
  }, [navigate]);

  const handleTogglePreview = useCallback(() => {
    if (preview) {
      removeField('preview');
    } else {
      const preview = true;
      updateSearchParams({ preview });
    }
  }, [preview, removeField, updateSearchParams]);

  const timeAgo = useMemo(() => {
    if (!template?.updatedAt) {
      return null;
    }
    return formatDistance(new Date(template?.updatedAt || new Date()), new Date(), { locale: es });
  }, [template?.updatedAt]);

  return (
    <AdminNavbar onOpenSidebar={onOpenSidebar}>
      {isLoading ? (
        <SkeletonList numberItemsToShow={1} />
      ) : (
        <AppBar
          position='static'
          sx={{
            bgcolor: 'background.paper',
            p: 0,
          }}
        >
          <Toolbar className={'p-2'}>
            <IconButton edge='start' color='secondary' aria-label='back' onClick={backNavigationHandler}>
              <ArrowBackIcon />
            </IconButton>
            <Box
              sx={{
                mr: 3,
                pl: 1,
              }}
            >
              <Typography
                color={'#1C2333'}
                sx={{
                  fontSize: 18,
                  fontWeight: 500,
                  font: 'Inter',
                  lineHeight: '21.78px',
                }}
              >
                {template?.name}
              </Typography>
            </Box>
            {!isMobile() && (
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <InfoOutlinedIcon
                  color='secondary'
                  sx={{
                    mr: '2px',
                    mt: '-1px',
                  }}
                />
                <Typography variant='subtitle2' color={'#1C2434'}>
                  {t('updatedAt', { updatedAtDistance: timeAgo })}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mr: 2,
              }}
            >
              <Button
                variant={'contained'}
                startIcon={<VisibilityOutlinedIcon sx={{ ml: 1 }} />}
                onClick={handleTogglePreview}
              >
                <Typography
                  variant='button'
                  textTransform={'none'}
                  sx={{
                    display: {
                      xs: 'none',
                      md: 'flex',
                    },
                  }}
                >
                  {preview ? t('exitPreview') : t('preview')}
                </Typography>
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      )}
    </AdminNavbar>
  );
};

export default memo(TemplateEditorNavBar);
