import { CellAlign, CellType, HeadCell } from '@dfl/mui-admin-layout';
import { renderTagList } from '@dfl/mui-react-common';
import { IArea } from 'modules/settings/nomenclatures/area/interfaces';
import { ITemplate } from 'modules/settings/templates/main/interfaces';

export const createdATColumn: HeadCell<any> = {
  field: 'createdAt',
  type: CellType.DATE,
  align: CellAlign.CENTER,
  headerName: 'common:createdAt',
};

export const codeColumn: HeadCell = {
  field: 'code',
  headerName: 'code',
};

export const areaColumn: HeadCell<ITemplate> = {
  field: 'areas',
  headerName: 'area:fields.areas',
  disablePadding: false,
  width: 200,
  renderCell: (name: string, data: ITemplate) =>
    renderTagList(data?.areas?.map((area) => (area as IArea)?.name || area) || [], 2),
};
