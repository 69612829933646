import { createSvgIcon } from '@mui/material';

const AreaIcon = createSvgIcon(
  <svg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10 6.8L15 4.4L10 2V10'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.10236 10L1.43061 12.8238C1.29988 12.9022 1.19115 13.0159 1.11551 13.1534C1.03986 13.2908 1 13.4471 1 13.6061C1 13.7652 1.03986 13.9214 1.11551 14.0589C1.19115 14.1963 1.29988 14.3101 1.43061 14.3885L8.65059 18.759C8.90884 18.9169 9.2018 19 9.5 19C9.79821 19 10.0912 18.9169 10.3494 18.759L17.5694 14.3885C17.7001 14.3101 17.8088 14.1963 17.8845 14.0589C17.9601 13.9214 18 13.7652 18 13.6061C18 13.4471 17.9601 13.2908 17.8845 13.1534C17.8088 13.0159 17.7001 12.9022 17.5694 12.8238L12.8976 10.009'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M5 11L13.5 16.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M14 11L5.5 16.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>,
  'AreaIcon',
);

export default AreaIcon;
