import { ComponentThemeType } from './theme.types';

export const papersTheme: ComponentThemeType = {
  MuiPaper: {
    styleOverrides: {
      elevation1: {
        borderRadius: 10,
        backgroundColor: 'white',
        boxShadow: 'none',
      },
      rounded: {
        backgroundColor: 'white',
        borderRadius: 10,
      },
    },
  },
};
