import { CellAlign, HeadCell } from '@dfl/mui-admin-layout';
import { areaColumn, createdATColumn } from 'modules/common/constants';
import { TemplateRowActions } from 'modules/settings/templates/main/components/TemplateRowActions';
import { TEMPLATE_PERMISSIONS } from 'modules/settings/templates/main/constants/template.permissions';
import { ITemplate } from 'modules/settings/templates/main/interfaces';
import { Link } from 'react-router-dom';
import i18n from 'settings/i18n';

export const templateNameColumn: HeadCell<ITemplate> = {
  field: 'name',
  headerName: 'templates:fields.name',
  disablePadding: false,
  renderCell: (name: string, data: ITemplate) => {
    return <Link to={`/settings/template-editor/${data._id as string}/`}>{name}</Link>;
  },
};

export const templateDescriptionColumn: HeadCell<ITemplate> = {
  field: 'description',
  headerName: 'templates:fields.description',
};

export const templateTypeColumn: HeadCell<ITemplate> = {
  field: 'serviceType',
  headerName: 'templates:fields.serviceType',
  renderCell: (value: string) => i18n.t(`templates:serviceTypeNames.${value}`),
  align: CellAlign.CENTER,
};

export const templateModuleColumn: HeadCell<ITemplate> = {
  field: 'module.name',
  headerName: 'templates:fields.module',
};

export const templateActionsColumn: HeadCell<ITemplate> = {
  field: 'actions',
  sortable: false,
  width: 100,
  permissions: TEMPLATE_PERMISSIONS.TEMPLATE_WRITE,
  headerName: 'common:actions',
  disablePadding: true,
  component: TemplateRowActions,
  align: CellAlign.CENTER,
};

export const templateColumns: Array<HeadCell<any>> = [
  templateNameColumn,
  templateDescriptionColumn,
  templateTypeColumn,
  templateModuleColumn,
  areaColumn,
  createdATColumn,
  templateActionsColumn,
];
