import { memo } from 'react';
import { FormCard } from '@dfl/mui-form-layout';
import { ChildrenProps } from '@dfl/mui-react-common';
import { Stack } from '@mui/material';
import { AuthOptions } from 'modules/authentication/components/AuthOptions';

const AuthLayout = ({ children }: ChildrenProps) => {
  return (
    <Stack alignItems={'center'} justifyContent={'center'} sx={{ minHeight: '100vh' }}>
      <FormCard rounded sx={{ maxWidth: 600 }}>
        <div>{children}</div>
      </FormCard>
      <AuthOptions />
    </Stack>
  );
};

export default memo(AuthLayout);
