import { CheckBoxIconChecked, CheckBoxIconUnChecked } from 'components/CheckBoxIcon/CheckBoxIcon';
import { ComponentThemeType } from './theme.types';
export const checkboxTheme: ComponentThemeType = {
  MuiCheckbox: {
    defaultProps: {
      icon: <CheckBoxIconUnChecked />,
      checkedIcon: <CheckBoxIconChecked />,
      indeterminateIcon: <CheckBoxIconChecked />,
      disableRipple: true
    },
  },
};
