import { Filter, FilterType } from '@dfl/mui-admin-layout';
import { createdATFilter } from 'modules/common/constants';
import { AREAS_LIST_KEY } from 'modules/settings/nomenclatures/area/constants';
import AreaService from 'modules/settings/nomenclatures/area/services/area.service';

export const areaFilter: Filter = {
  fetchFunc: AreaService.search,
  filter: 'audit:fields.areas',
  translate: true,
  type: FilterType.DYNAMIC_LIST,
  queryKey: AREAS_LIST_KEY,
  key: 'areas',
  field: 'areas',
};

export const templateFilters = [areaFilter, createdATFilter];
