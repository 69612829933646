import { createSvgIcon } from '@mui/material';

const SectionIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4 20L20 20C20.5523 20 21 19.5523 21 19L21 14C21 13.4477 20.5523 13 20 13L4 13C3.44772 13 3 13.4477 3 14L3 19C3 19.5523 3.44772 20 4 20Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4 10L20 10C20.5523 10 21 9.55228 21 9L21 6.5C21 5.94772 20.5523 5.5 20 5.5L4 5.5C3.44772 5.5 3 5.94771 3 6.5L3 9C3 9.55228 3.44772 10 4 10Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'SectionIcon',
);

export default SectionIcon;
