import { ComponentThemeType } from './theme.types';

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    dashed: true;
  }
}

export const cardsTheme: ComponentThemeType = {
  MuiCard: {
    variants: [
      {
        props: { variant: 'dashed' },
        style: {
          border: '2px dashed',
          borderColor: '#E4E4E4',
        },
      },
    ],
    styleOverrides: {
      root: ({ theme: { palette } }) => ({
        backgroundColor: palette.background.paper,
        boxShadow: '0px 4px 23px rgba(0, 0, 0, 0.1)',
      }),
    },
  },
};
