import { createSvgIcon } from '@mui/material';

const ExclamationSvg = createSvgIcon(
  <svg width='68' height='62' viewBox='0 0 68 62' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity='0.5' filter='url(#filter0_f_1650_31297)'>
      <path
        d='M42.3187 17.7476C37.387 17.7476 33.3845 21.792 33.3845 26.7734C33.3845 31.7566 37.387 35.7993 42.3187 35.7993C47.2593 35.7993 51.2528 31.7566 51.2528 26.7734C51.2528 21.792 47.2593 17.7476 42.3187 17.7476Z'
        fill='#2A70D7'
      />
    </g>
    <path
      d='M0.46444 35.7149C0.46444 22.3941 11.1666 11.5865 24.343 11.5865C37.5433 11.5865 48.2215 22.3938 48.2215 35.7149C48.2215 49.0408 37.5434 59.8432 24.343 59.8432C11.1666 59.8432 0.46444 49.0405 0.46444 35.7149Z'
      fill='#2A70D7'
      fillOpacity='0.35'
      stroke='url(#paint0_linear_1650_31297)'
      strokeWidth='0.928879'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M30.4038 11.8551C33.6918 9.13896 37.8924 7.50879 42.4675 7.50879C53.0171 7.50879 61.5445 16.1449 61.5445 26.7815C61.5445 35.9178 55.2576 43.5735 46.8019 45.5554C48.1043 42.5422 48.8265 39.2143 48.8265 35.7164C48.8265 24.203 40.9967 14.5264 30.4038 11.8551Z'
      fill='#2A70D7'
    />
    <g opacity='0.5' filter='url(#filter1_f_1650_31297)'>
      <path
        d='M46.6613 45.4249C47.9637 42.4117 48.6859 39.0838 48.6859 35.5859C48.6859 24.0725 40.8561 14.3959 30.2632 11.7246C25.9834 15.2601 23.25 20.6357 23.25 26.651C23.25 37.2915 31.7965 45.9237 42.3269 45.9237C43.8182 45.9237 45.2691 45.7512 46.6613 45.4249Z'
        fill='#2A70D7'
      />
    </g>
    <g filter='url(#filter2_d_1650_31297)'>
      <path
        d='M24.1444 24.3726C23.0061 24.3726 22.0576 25.354 22.0576 26.5264V37.3446C22.0576 38.5219 23.0061 39.474 24.1444 39.474C25.2826 39.474 26.2074 38.5219 26.2074 37.3446V26.5264C26.2074 25.354 25.2826 24.3726 24.1444 24.3726Z'
        fill='url(#paint1_linear_1650_31297)'
      />
      <path
        d='M22.0809 45.1051C22.0809 46.2799 23.0057 47.2589 24.1676 47.2589C25.3059 47.2589 26.2307 46.2799 26.2307 45.1051C26.2307 43.9302 25.3059 42.9757 24.1439 42.9757C23.0057 42.9757 22.0809 43.9302 22.0809 45.1051Z'
        fill='url(#paint2_linear_1650_31297)'
      />
      <path
        d='M22.2434 26.5264C22.2434 25.4509 23.1144 24.5583 24.1444 24.5583C25.1717 24.5583 26.0217 25.4481 26.0217 26.5264V37.3446C26.0217 38.4247 25.1747 39.2882 24.1444 39.2882C23.1114 39.2882 22.2434 38.422 22.2434 37.3446V26.5264ZM24.1676 47.0731C23.1151 47.0731 22.2666 46.1843 22.2666 45.1051C22.2666 44.0273 23.1138 43.1615 24.1439 43.1615C25.1991 43.1615 26.0449 44.0287 26.0449 45.1051C26.0449 46.1857 25.1951 47.0731 24.1676 47.0731Z'
        stroke='url(#paint3_linear_1650_31297)'
        strokeWidth='0.371552'
      />
    </g>
    <defs>
      <filter
        id='filter0_f_1650_31297'
        x='16.6647'
        y='1.02773'
        width='51.3081'
        height='51.4914'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='8.35991' result='effect1_foregroundBlur_1650_31297' />
      </filter>
      <filter
        id='filter1_f_1650_31297'
        x='12.1034'
        y='0.578057'
        width='47.7289'
        height='56.4923'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='5.57328' result='effect1_foregroundBlur_1650_31297' />
      </filter>
      <filter
        id='filter2_d_1650_31297'
        x='17.4132'
        y='19.7282'
        width='22.7507'
        height='41.4638'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dx='4.6444' dy='4.6444' />
        <feGaussianBlur stdDeviation='4.6444' />
        <feColorMatrix type='matrix' values='0 0 0 0 0.25098 0 0 0 0 0.588235 0 0 0 0 0.996078 0 0 0 1 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1650_31297' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1650_31297' result='shape' />
      </filter>
      <linearGradient
        id='paint0_linear_1650_31297'
        x1='7.75'
        y1='16.8523'
        x2='39.1912'
        y2='55.3973'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.25' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_1650_31297'
        x1='24.1446'
        y1='25.6635'
        x2='20.3027'
        y2='25.3696'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.75' />
        <stop offset='1' stopColor='white' stopOpacity='0.2' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_1650_31297'
        x1='24.1446'
        y1='25.6635'
        x2='20.3027'
        y2='25.3696'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.75' />
        <stop offset='1' stopColor='white' stopOpacity='0.2' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_1650_31297'
        x1='22.7219'
        y1='27.0388'
        x2='29.1399'
        y2='28.4882'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.25' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
    </defs>
  </svg>,
  'ExclamationSvg',
);

export default ExclamationSvg;
