import { createSvgIcon } from '@mui/material';

const EditIcon = createSvgIcon(
  <svg width='19' height='24' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.92015 2.15969H3.62408C2.70557 2.15969 2.24631 2.15969 1.89548 2.33845C1.58689 2.49568 1.33599 2.74658 1.17875 3.05518C1 3.406 1 3.86526 1 4.78377V9.37592C1 10.2944 1 10.7537 1.17875 11.1045C1.33599 11.4131 1.58689 11.664 1.89548 11.8212C2.24631 12 2.70557 12 3.62408 12H8.21623C9.13474 12 9.594 12 9.94482 11.8212C10.2534 11.664 10.5043 11.4131 10.6616 11.1045C10.8403 10.7537 10.8403 10.2944 10.8403 9.37592V7.07985M4.28009 8.7199H5.19553C5.46296 8.7199 5.59668 8.7199 5.72251 8.68969C5.83407 8.6629 5.94072 8.61873 6.03855 8.55878C6.14889 8.49116 6.24344 8.39661 6.43254 8.20751L11.6603 2.97972C12.1132 2.52683 12.1132 1.79255 11.6603 1.33967C11.2074 0.886779 10.4732 0.886778 10.0203 1.33966L4.79247 6.56746C4.60337 6.75656 4.50882 6.85111 4.44121 6.96145C4.38126 7.05928 4.33708 7.16593 4.3103 7.27749C4.28009 7.40333 4.28009 7.53704 4.28009 7.80447V8.7199Z'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'EditIcon',
);

export default EditIcon;
