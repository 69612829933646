import { createSvgIcon } from '@mui/material';

const SurveyIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.05566 5.25H4.22233'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.05566 8.41675H4.22233'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.05566 11.5833H4.22233'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.05566 14.75H4.22233'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.722 2.08325H4.22201C3.34755 2.08325 2.63867 2.79213 2.63867 3.66659V16.3333C2.63867 17.2077 3.34755 17.9166 4.22201 17.9166H13.722C14.5965 17.9166 15.3053 17.2077 15.3053 16.3333V3.66659C15.3053 2.79213 14.5965 2.08325 13.722 2.08325Z'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.99316 6.83325H10.9515'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M6.99316 10H12.139' stroke='currentColor' strokeWidth={1.5} strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M6.99316 13.1667H10.5557'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'SurveyIcon',
);

export default SurveyIcon;
