import { createSvgIcon } from '@mui/material';

const RulesIcon = createSvgIcon(
  <svg width='20' height='20' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.75 1.25H2C1.58579 1.25 1.25 1.58579 1.25 2V5.75C1.25 6.16421 1.58579 6.5 2 6.5H5.75C6.16421 6.5 6.5 6.16421 6.5 5.75V2C6.5 1.58579 6.16421 1.25 5.75 1.25Z'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.75 9.5H2C1.58579 9.5 1.25 9.83579 1.25 10.25V14C1.25 14.4142 1.58579 14.75 2 14.75H5.75C6.16421 14.75 6.5 14.4142 6.5 14V10.25C6.5 9.83579 6.16421 9.5 5.75 9.5Z'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M9.5 2H14.75' stroke='currentColor' strokeWidth={1.5} strokeLinecap='round' strokeLinejoin='round' />
    <path d='M9.5 5.75H14.75' stroke='currentColor' strokeWidth={1.5} strokeLinecap='round' strokeLinejoin='round' />
    <path d='M9.5 10.25H14.75' stroke='currentColor' strokeWidth={1.5} strokeLinecap='round' strokeLinejoin='round' />
    <path d='M9.5 14H14.75' stroke='currentColor' strokeWidth={1.5} strokeLinecap='round' strokeLinejoin='round' />
  </svg>,
  'RulesIcon',
);

export default RulesIcon;
