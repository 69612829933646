import { TypographyThemeType } from './theme.types';

export const typographiesTheme: TypographyThemeType = {
  fontFamily: ['-apple-system', 'Inter'].join(','),
  h1: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
    color: '#1C2333',
  },
  h2: {
    fontSize: 18,
    fontWeight: 700,
  },
  h3: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '19.36px',
    font: 'Inter'
  },
  h4: {
    fontSize: 35,
    fontWeight: 700,
  },
  h5: {
    fontSize: 24,
    fontWeight: 700,
  },
  h6: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 1.5,
    whiteSpace: 'normal',
    textTransform: 'none',
  },
  subtitle1: {
    fontSize: 14,
  },
  subtitle2: {
    fontSize: 14,
    color: '#9098A5',
  },
  body1: {
    fontSize: 16,
    color: '#111927',
    fontWeight: 500,
  },
  body2: {
    // fontSize: 15,
    fontSize: 16,
    fontWeight: 400,
    color: '#111927',
  },
};
