import { ComponentThemeType } from './theme.types';

export const inputsTheme: ComponentThemeType = {
  MuiFormControl: {
    styleOverrides: {
      root: {
        width: '100%',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        width: '100%',
      },
      notchedOutline: {
        borderRadius: 8,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: '#666666',
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      disableRipple: true,
    },
  },
};
