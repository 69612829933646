export enum ImportanceLevel {
  MAJOR = '1',
  MEDIUM = '2',
  MINOR = '3',
}

export enum Classification {
  PERFORMANCE = 'PERFORMANCE',
  INFRASTRUCTURE = 'INFRASTRUCTURE',
  RECORDS = 'RECORDS',
  EQUIPMENT = 'EQUIPMENT',
}

export interface ITemplateItemSettings {
  importanceLevel: ImportanceLevel | number;
  classification: Classification;
  criticalPoint: boolean;
}

export type ItemAnswerOptions = {
  _id?: string;
  text: string;
  color: string;
  score?: number | undefined;
  order?: number;
  failed?: boolean;
  na?: boolean;
};

export type ItemAnswerSettings = {
  _id?: string;
  items: ItemAnswerOptions[];
  scoreable?: boolean;
};
