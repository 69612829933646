import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TEMPLATES_LIST_KEY } from 'modules/settings/templates/main/constants';
import { TemplateService } from 'modules/settings/templates/main/services';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export const useDeleteTemplate = (id: string, onClose: () => void) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('templates');
  return useMutation(() => TemplateService.delete(id), {
    onSuccess: (data) => {
      toast.success(t('successDeleted'));
      onClose?.();
      queryClient.invalidateQueries([TEMPLATES_LIST_KEY]);
      queryClient.invalidateQueries([id]);
    },
  });
};
