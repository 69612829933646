import { useState } from 'react';

const useRowAction = () => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const handleAction = (action?: () => void) => {
    setOpenTooltip(false);
    if (typeof action === 'function') {
      action();
    }
  };

  return {
    openTooltip,
    setOpenTooltip,
    handleAction,
  };
};

export default useRowAction;
