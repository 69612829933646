import { memo } from 'react';
import { useToggle } from '@dfl/hook-utils';
import { AdminMain } from '@dfl/mui-admin-layout';
import { ChildrenProps } from '@dfl/mui-react-common';
import TemplateEditorSideBar from 'modules/settings/templates/template-editor/components/Sidebar/TemplateEditorSideBar';
import TemplateEditorNavBar from 'modules/settings/templates/template-editor/components/TemplateEditorNavBar/TemplateEditorNavBar';

const QuizLayout = ({ children }: ChildrenProps) => {
  const { isOpen, onOpen, onClose } = useToggle(false);

  return (
    <>
      <AdminMain>{children}</AdminMain>
      <TemplateEditorSideBar onClose={onClose} open={isOpen} />
      <TemplateEditorNavBar onOpenSidebar={onOpen} />
    </>
  );
};

export default memo(QuizLayout);
