import { createSvgIcon } from '@mui/material';

const AuditIcon = createSvgIcon(
  <svg width='20' height='22' viewBox='0 0 15 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1 15V3C1 2.46957 1.21401 1.96086 1.59494 1.58579C1.97587 1.21071 2.49253 1 3.03125 1H14V17H3.03125C2.49253 17 1.97587 16.7893 1.59494 16.4142C1.21401 16.0391 1 15.5304 1 15ZM1 15C1 14.4696 1.21401 13.9609 1.59494 13.5858C1.97587 13.2107 2.49253 13 3.03125 13H14'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5 6.5L6.66667 8L10 5'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'AuditIcon',
);

export default AuditIcon;
