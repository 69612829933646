import { ITemplate, ServiceType } from 'modules/settings/templates/main/interfaces';
import { Classification, ImportanceLevel, ITemplateItemSettings } from '../interfaces/IItemConfigs';

export const templateDefaults: ITemplate = {
  name: '',
  description: '',
  sections: [],
  generalInfo: [],
  serviceType: ServiceType.AUDIT,
  module: null,
  areas: [],
};
export const initGeneralSettingsValues: ITemplateItemSettings = {
  importanceLevel: Number(ImportanceLevel.MEDIUM),
  classification: Classification.EQUIPMENT,
  criticalPoint: false,
};
