import { ComponentThemeType } from './theme.types';

export const navbarTheme: ComponentThemeType = {
  MuiAppBar: {
    styleOverrides: {
      root: ({ theme: { palette } }) => ({
        boxShadow: 'none !important',
        borderBottom: `1px solid ${palette.tertiary.light} `,
      }),
    },
  },
};
