import { memo } from 'react';
import { useToggle } from '@dfl/hook-utils';
import { ConfirmDialog } from '@dfl/mui-admin-layout';
import { useParamsLink } from '@dfl/react-security';
import { ListItemIcon, MenuItem, Typography } from '@mui/material';
import { EditIcon, TrashIcon } from 'components/SvgIcons';
import useRowAction from 'hooks/useRowAction';
import ActionTableMenu from 'modules/common/components/ActionTableMenu/ActionTableMenu';
import { useDeleteArea } from 'modules/settings/nomenclatures/area/hooks/useDeleteArea';
import { useTranslation } from 'react-i18next';

type UserStatusProps = {
  rowId: string;
};

const AreaRowActions = ({ rowId }: UserStatusProps) => {
  const { t } = useTranslation('area');
  const { isOpen, onClose, onOpen } = useToggle();
  const handleEdit = useParamsLink({ edit: rowId });
  const { mutate, isLoading, error } = useDeleteArea(rowId, onClose);
  const { openTooltip, setOpenTooltip, handleAction } = useRowAction();

  return (
    <>
      <ConfirmDialog
        confirmButtonText={t('common:accept')}
        title={t('common:deleteConfirmation.title')}
        onConfirm={mutate}
        confirmationMessage={t('common:deleteConfirmation.confirmation')}
        isLoading={isLoading}
        error={error}
        onClose={onClose}
        open={isOpen}
      />
      <ActionTableMenu onClose={handleAction} open={openTooltip} setOpenTooltip={setOpenTooltip}>
        <MenuItem
          onClick={() => {
            handleAction(handleEdit);
          }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <Typography color='secondary'>{t('common:edit')}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleAction(onOpen);
          }}
        >
          <ListItemIcon>
            <TrashIcon />
          </ListItemIcon>
          <Typography color='secondary'>{t('common:delete')}</Typography>
        </MenuItem>
      </ActionTableMenu>
    </>
  );
};

export default memo(AreaRowActions);
