import { createSvgIcon } from '@mui/material';

const TemplateIcon = createSvgIcon(
  <svg width='28' height='28' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18.3333 5H6.66667C5.74619 5 5 5.74619 5 6.66667V18.3333C5 19.2538 5.74619 20 6.66667 20H18.3333C19.2538 20 20 19.2538 20 18.3333V6.66667C20 5.74619 19.2538 5 18.3333 5Z'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.3572 9.28572H15.7143'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.28577 12.5H14.6429'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.4286 15.7143H15.7143'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'TemplateIcon',
);

export default TemplateIcon;
