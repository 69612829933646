import { lazy } from 'react';
import { RouteConfig } from '@dfl/react-security';
import TemplateEditorApp from 'routes/TemplateEditorApp';

const loadMainApp = () => import('routes/MainApp');
export const MainApp = lazy(loadMainApp);

const loadQuizApp = () => import('routes/QuizApp');
export const QuizApp = lazy(loadQuizApp);

const loadQuizSurveyApp = () => import('routes/QuizSurveyApp');
export const QuizSurveyApp = lazy(loadQuizSurveyApp);

const loadAuthApp = () => import('routes/AuthApp');
export const AuthApp = lazy(loadAuthApp);

export const routes: RouteConfig = {
  Auth: {
    path: '/auth/*',
    onlyAnonymous: true,
    component: AuthApp,
  },
  QuizLayout: {
    path: '/quiz/*',
    exact: false,
    authenticated: true,
    redirect: '/auth/login',
    component: QuizApp,
  },
  TemplateEditorLayout: {
    path: '/settings/template-editor/:id/*',
    authenticated: true,
    redirect: '/auth/login',
    component: TemplateEditorApp,
  },
  MainLayout: {
    path: '*',
    authenticated: true,
    redirect: '/auth/login',
    component: MainApp,
  },
  QuizSurveyLayout: {
    path: '/quiz/survey/*',
    exact: false,
    authenticated: false,
    component: QuizSurveyApp,
  },
};
