import { createSvgIcon } from '@mui/material';

const CheckSvg = createSvgIcon(
  <svg width='71' height='69' viewBox='0 0 71 69' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M41.7782 9.92085L57.8022 14.1829C63.7083 15.7538 66.0849 20.0067 64.5224 25.7953L59.2303 45.4006C58.2122 49.172 56.1292 51.5234 53.2091 52.3119C53.3239 51.5221 53.3829 50.6993 53.3829 49.8469V31.3771C53.3829 23.3743 48.2131 17.9939 40.4927 17.9939H29.7422L30.1072 16.6416C31.6697 10.853 35.9658 8.37489 41.7782 9.92085Z'
      fill='#2A70D7'
    />
    <path
      d='M20.6374 18.1305H40.3827C44.1312 18.1305 47.228 19.4341 49.3884 21.6787C51.5503 23.9248 52.8085 27.1489 52.8085 31.0493V49.519C52.8085 53.4077 51.5505 56.6262 49.3886 58.8697C47.2281 61.1116 44.1312 62.4152 40.3827 62.4152H20.6374C16.8888 62.4152 13.7859 61.1115 11.6197 58.8692C9.45215 56.6256 8.1888 53.4071 8.1888 49.519V31.0493C8.1888 27.1495 9.45236 23.9254 11.6199 21.6791C13.7861 19.4342 16.8888 18.1305 20.6374 18.1305Z'
      fill='#2A70D7'
      fillOpacity='0.35'
      stroke='url(#paint0_linear_1574_14467)'
      strokeWidth='0.928879'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <g opacity='0.5' filter='url(#filter0_f_1574_14467)'>
      <path
        d='M53.3949 51.3643C53.4585 50.7664 53.4909 50.1509 53.4909 49.519V31.0493C53.4909 23.0464 48.3211 17.666 40.6006 17.666H30.0771L25.2515 35.5429C23.6841 41.3495 26.1443 45.497 31.8806 47.0227L47.6953 51.2291C49.84 51.7995 51.7541 51.8357 53.3949 51.3643Z'
        fill='#2A70D7'
      />
    </g>
    <mask id='path-4-inside-1_1574_14467' fill='white'>
      <path d='M27.8437 47.7181C27.3294 47.7181 26.815 47.5251 26.4224 47.1368L20.9737 41.748C20.1884 40.9713 20.1884 39.7133 20.9737 38.9389C21.759 38.1622 23.0287 38.16 23.814 38.9366L27.8437 42.922L37.3221 33.5478C38.1073 32.7712 39.3771 32.7712 40.1624 33.5478C40.9476 34.3245 40.9476 35.5825 40.1624 36.3592L29.265 47.1368C28.8724 47.5251 28.358 47.7181 27.8437 47.7181Z' />
    </mask>
    <path
      d='M27.8437 47.7181C27.3294 47.7181 26.815 47.5251 26.4224 47.1368L20.9737 41.748C20.1884 40.9713 20.1884 39.7133 20.9737 38.9389C21.759 38.1622 23.0287 38.16 23.814 38.9366L27.8437 42.922L37.3221 33.5478C38.1073 32.7712 39.3771 32.7712 40.1624 33.5478C40.9476 34.3245 40.9476 35.5825 40.1624 36.3592L29.265 47.1368C28.8724 47.5251 28.358 47.7181 27.8437 47.7181Z'
      fill='url(#paint1_linear_1574_14467)'
    />
    <path
      d='M20.9737 38.9389L21.1042 39.0712L21.1044 39.071L20.9737 38.9389ZM27.8437 42.922L27.7131 43.0541L27.8437 43.1833L27.9743 43.0541L27.8437 42.922ZM40.1624 36.3592L40.0317 36.2271L40.1624 36.3592ZM29.265 47.1368L29.3956 47.2689L29.265 47.1368ZM27.8437 47.5323C27.3757 47.5323 26.9091 47.3569 26.553 47.0047L26.2918 47.2689C26.7209 47.6933 27.283 47.9039 27.8437 47.9039V47.5323ZM26.553 47.0047L21.1044 41.6159L20.8431 41.8801L26.2918 47.2689L26.553 47.0047ZM21.1044 41.6159C20.3925 40.9118 20.3928 39.7727 21.1042 39.0712L20.8433 38.8066C19.9841 39.6538 19.9844 41.0308 20.8431 41.8801L21.1044 41.6159ZM21.1044 39.071C21.8175 38.3656 22.9708 38.3639 23.6834 39.0687L23.9446 38.8045C23.0867 37.956 21.7005 37.9588 20.8431 38.8068L21.1044 39.071ZM23.6834 39.0687L27.7131 43.0541L27.9743 42.7899L23.9446 38.8045L23.6834 39.0687ZM27.9743 43.0541L37.4527 33.6799L37.1914 33.4157L27.7131 42.7899L27.9743 43.0541ZM37.4527 33.6799C38.1656 32.9748 39.3188 32.9748 40.0317 33.6799L40.293 33.4157C39.4353 32.5675 38.0491 32.5675 37.1914 33.4157L37.4527 33.6799ZM40.0317 33.6799C40.7435 34.3838 40.7435 35.5231 40.0317 36.2271L40.293 36.4913C41.1518 35.6419 41.1518 34.2651 40.293 33.4157L40.0317 33.6799ZM40.0317 36.2271L29.1344 47.0047L29.3956 47.2689L40.293 36.4913L40.0317 36.2271ZM29.1344 47.0047C28.7783 47.3569 28.3116 47.5323 27.8437 47.5323V47.9039C28.4044 47.9039 28.9664 47.6933 29.3956 47.2689L29.1344 47.0047Z'
      fill='url(#paint2_linear_1574_14467)'
      fillOpacity='0.5'
      mask='url(#path-4-inside-1_1574_14467)'
    />
    <defs>
      <filter
        id='filter0_f_1574_14467'
        x='8.0685'
        y='0.946188'
        width='62.1423'
        height='67.4636'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='8.35991' result='effect1_foregroundBlur_1574_14467' />
      </filter>
      <linearGradient
        id='paint0_linear_1574_14467'
        x1='14.975'
        y1='22.9335'
        x2='43.7709'
        y2='58.8621'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.25' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_1574_14467'
        x1='39.3073'
        y1='35.6294'
        x2='16.6427'
        y2='36.6495'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0.2' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_1574_14467'
        x1='22.5787'
        y1='37.9142'
        x2='39.8722'
        y2='38.046'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
    </defs>
  </svg>,
  'CheckSvg',
);

export default CheckSvg;
