import { ENTITY } from 'constants/entity.enum';
import { memo, ReactNode, useCallback, useMemo } from 'react';
import { AdminSidebar } from '@dfl/mui-admin-layout';
import { ChildrenProps, ConditionContainer, SkeletonForm } from '@dfl/mui-react-common';
import { useSearchParamsChange } from '@dfl/react-security';
import { InFilter } from '@dofleini/query-builder';
import { Box, Typography } from '@mui/material';
import { useFindAreas } from 'modules/settings/nomenclatures/area/hooks/useFindAreas';
import { IArea } from 'modules/settings/nomenclatures/area/interfaces';
import { useFindOneTemplate } from 'modules/settings/templates/main/hooks/useFindOneTemplate';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { sortByPositionInArray } from 'utils/sort-by-position-in-array';
import TemplateEditorSidebarBody, { WhiteDot } from './TemplateEditorSidebarBody';

declare type AdminSidebarProps = ChildrenProps & {
  onClose: () => void;
  open: boolean;
};

export type ItemQueryParams = { areaId?: string; areaCategoryId?: string; intro?: boolean };

export type TemplateSidebarItem = {
  items?: TemplateSidebarItem[];
  params?: ItemQueryParams;
  count?: number;
  boldFont?: boolean;
  active?: boolean;
  text: string;
  onClick?: () => void;
  className?: string;
  icon?: ReactNode;
};

const TemplateEditorSideBar = (props: AdminSidebarProps) => {
  const { t } = useTranslation();
  const { update } = useSearchParamsChange();
  const { id: templateId } = useParams();
  const { data: template } = useFindOneTemplate(templateId as string);

  const areaFilter = new InFilter({
    field: '_id',
    value: template?.areas,
    objectId: true,
  });
  const { data, isLoading } = useFindAreas(
    areaFilter,
    { entity: ENTITY.TEMPLATE, entityId: templateId as string },
    true,
  );

  const areasData = useMemo(() => {
    return sortByPositionInArray(data?.data || [], (template?.areas as string[]) || []);
  }, [data?.data, template?.areas]);

  const sidebarItems = useMemo(() => {
    const sidebarMenu: TemplateSidebarItem[] = [];
    if (areasData) {
      areasData.forEach((area: IArea) => {
        sidebarMenu.push({
          text: area.name,
          params: { areaId: area._id as string },
          count: area.sectionCount,
          boldFont: true,
          icon: <WhiteDot />,
          items:
            area.categoryArea?.map((category) => {
              return {
                text: category.name,
                params: {
                  areaId: area._id as string,
                  areaCategoryId: category._id as string,
                },
                count: category.sectionCount,
              };
            }) || [],
        });
      });
    }
    return sidebarMenu;
  }, [areasData]);

  const itemClickHandler = useCallback(
    (item: TemplateSidebarItem) => {
      const keysToRemove = item.params?.areaCategoryId ? [] : ['areaCategoryId'];
      update(item.params || {}, keysToRemove);
    },
    [update],
  );

  return (
    <AdminSidebar {...props}>
      <ConditionContainer
        active={!isLoading}
        alternative={
          <Box paddingLeft={1} paddingRight={2} paddingTop={2}>
            <SkeletonForm numberItemsToShow={10} />
          </Box>
        }
      >
        <Box sx={{ flexGrow: 1, mt: 0 }}>
          <Typography variant={'h3'} className={'font-bold text-gray-400 pl-8 pt-8 uppercase'}>
            {t('areas')}
          </Typography>
          <TemplateEditorSidebarBody menu={sidebarItems} onItemClick={itemClickHandler} />
        </Box>
      </ConditionContainer>
    </AdminSidebar>
  );
};

export default memo(TemplateEditorSideBar);
