import { createSvgIcon } from '@mui/material';

const SurveySvg = createSvgIcon(
  <svg width='65' height='61' viewBox='0 0 65 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity='0.5' filter='url(#filter0_f_1650_19736)'>
      <path
        d='M41.0895 29.9776L38.0422 18.6193C36.9368 14.4994 34.1703 12.652 30.5327 13.5969L18.2127 16.7972C14.5155 17.7575 13.0839 20.6954 14.1892 24.8153L17.2366 36.1736C18.3598 40.36 21.0604 42.1519 24.7576 41.1916L37.0776 37.9913C40.7152 37.0464 42.2127 34.164 41.0895 29.9776Z'
        fill='#2A70D7'
      />
    </g>
    <g opacity='0.35' filter='url(#filter1_b_1650_19736)'>
      <mask id='path-2-inside-1_1650_19736' fill='white'>
        <path d='M47.2483 15.7739H29.877C23.5466 15.7739 19.96 19.3031 19.96 25.5319V42.6085C19.96 48.8373 23.5466 52.3665 29.877 52.3665H47.2483C53.5787 52.3665 57.1488 48.8373 57.1488 42.6085V25.5319C57.1488 19.3031 53.5787 15.7739 47.2483 15.7739Z' />
      </mask>
      <path
        d='M47.2483 15.7739H29.877C23.5466 15.7739 19.96 19.3031 19.96 25.5319V42.6085C19.96 48.8373 23.5466 52.3665 29.877 52.3665H47.2483C53.5787 52.3665 57.1488 48.8373 57.1488 42.6085V25.5319C57.1488 19.3031 53.5787 15.7739 47.2483 15.7739Z'
        fill='#2A70D7'
      />
      <path
        d='M47.2483 16.7739C47.8006 16.7739 48.2483 16.3262 48.2483 15.7739C48.2483 15.2216 47.8006 14.7739 47.2483 14.7739V16.7739ZM47.2483 14.7739C46.6961 14.7739 46.2483 15.2216 46.2483 15.7739C46.2483 16.3262 46.6961 16.7739 47.2483 16.7739V14.7739ZM47.2483 14.7739H29.877V16.7739H47.2483V14.7739ZM29.877 14.7739C26.5303 14.7739 23.7689 15.7095 21.8432 17.6043C19.9152 19.5014 18.96 22.2271 18.96 25.5319H20.96C20.96 22.6079 21.7981 20.4546 23.246 19.0299C24.6962 17.6029 26.8933 16.7739 29.877 16.7739V14.7739ZM18.96 25.5319V42.6085H20.96V25.5319H18.96ZM18.96 42.6085C18.96 45.9133 19.9152 48.639 21.8432 50.5361C23.7689 52.4309 26.5303 53.3665 29.877 53.3665V51.3665C26.8933 51.3665 24.6962 50.5375 23.246 49.1105C21.7981 47.6858 20.96 45.5325 20.96 42.6085H18.96ZM29.877 53.3665H47.2483V51.3665H29.877V53.3665ZM47.2483 53.3665C50.5951 53.3665 53.3529 52.4309 55.2747 50.5352C57.1985 48.6377 58.1488 45.912 58.1488 42.6085H56.1488C56.1488 45.5338 55.3141 47.6871 53.8702 49.1114C52.4244 50.5376 50.2319 51.3665 47.2483 51.3665V53.3665ZM58.1488 42.6085V25.5319H56.1488V42.6085H58.1488ZM58.1488 25.5319C58.1488 22.2284 57.1985 19.5027 55.2747 17.6052C53.3529 15.7096 50.5951 14.7739 47.2483 14.7739V16.7739C50.2319 16.7739 52.4244 17.6029 53.8702 19.029C55.3141 20.4533 56.1488 22.6066 56.1488 25.5319H58.1488Z'
        fill='url(#paint0_linear_1650_19736)'
        mask='url(#path-2-inside-1_1650_19736)'
      />
    </g>
    <g filter='url(#filter2_b_1650_19736)'>
      <path
        d='M15.7934 12.4119L32.5744 7.98751C38.6896 6.37521 43.0667 8.87482 44.7051 14.8914L44.9441 15.7689H29.7918C23.4614 15.7689 19.8747 19.298 19.8747 25.5269V42.6034C19.8747 44.8292 20.3327 46.7102 21.2102 48.2087C17.2975 47.919 14.5047 45.3854 13.2718 40.8579L8.78006 24.3632C7.14164 18.3466 9.67811 14.0242 15.7934 12.4119Z'
        fill='#2A70D7'
      />
    </g>
    <g opacity='0.5' filter='url(#filter3_f_1650_19736)'>
      <path
        d='M45.0293 15.7739H29.877C23.5466 15.7739 19.96 19.3031 19.96 25.5319V42.6085C19.96 44.8342 20.4179 46.7153 21.2954 48.2138C22.5823 48.3091 23.9903 48.1617 25.5038 47.7626L42.2848 43.3383C48.4 41.726 50.9205 37.4078 49.2821 31.3912L45.0293 15.7739Z'
        fill='#2A70D7'
      />
    </g>
    <g filter='url(#filter4_d_1650_19736)'>
      <mask id='path-6-inside-2_1650_19736' fill='white'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M39.3203 23.5801C38.4701 23.5801 37.7699 24.2696 37.7699 25.1233V43.8715C37.7699 44.7088 38.4701 45.3983 39.3203 45.3983C40.1872 45.3983 40.8874 44.7088 40.8874 43.8715V25.1233C40.8874 24.2696 40.1872 23.5801 39.3203 23.5801ZM30.3771 29.6059C29.5268 29.6059 28.8267 30.2954 28.8267 31.1491V43.8394C28.8267 44.6767 29.5268 45.3662 30.3771 45.3662C31.244 45.3662 31.9442 44.6767 31.9442 43.8394V31.1491C31.9442 30.2954 31.244 29.6059 30.3771 29.6059ZM46.2425 38.1019C46.2425 37.2482 46.9427 36.5587 47.8096 36.5587C48.6598 36.5587 49.36 37.2482 49.36 38.1019V44.0941C49.36 44.9314 48.6598 45.6209 47.7929 45.6209C46.9427 45.6209 46.2425 44.9314 46.2425 44.0941V38.1019Z'
        />
      </mask>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.3203 23.5801C38.4701 23.5801 37.7699 24.2696 37.7699 25.1233V43.8715C37.7699 44.7088 38.4701 45.3983 39.3203 45.3983C40.1872 45.3983 40.8874 44.7088 40.8874 43.8715V25.1233C40.8874 24.2696 40.1872 23.5801 39.3203 23.5801ZM30.3771 29.6059C29.5268 29.6059 28.8267 30.2954 28.8267 31.1491V43.8394C28.8267 44.6767 29.5268 45.3662 30.3771 45.3662C31.244 45.3662 31.9442 44.6767 31.9442 43.8394V31.1491C31.9442 30.2954 31.244 29.6059 30.3771 29.6059ZM46.2425 38.1019C46.2425 37.2482 46.9427 36.5587 47.8096 36.5587C48.6598 36.5587 49.36 37.2482 49.36 38.1019V44.0941C49.36 44.9314 48.6598 45.6209 47.7929 45.6209C46.9427 45.6209 46.2425 44.9314 46.2425 44.0941V38.1019Z'
        fill='url(#paint1_linear_1650_19736)'
      />
      <path
        d='M38.1699 25.1233C38.1699 24.4935 38.688 23.9801 39.3203 23.9801V23.1801C38.2522 23.1801 37.3699 24.0457 37.3699 25.1233H38.1699ZM38.1699 43.8715V25.1233H37.3699V43.8715H38.1699ZM39.3203 44.9983C38.6852 44.9983 38.1699 44.4821 38.1699 43.8715H37.3699C37.3699 44.9355 38.255 45.7983 39.3203 45.7983V44.9983ZM40.4874 43.8715C40.4874 44.4793 39.9749 44.9983 39.3203 44.9983V45.7983C40.3995 45.7983 41.2874 44.9382 41.2874 43.8715H40.4874ZM40.4874 25.1233V43.8715H41.2874V25.1233H40.4874ZM39.3203 23.9801C39.9721 23.9801 40.4874 24.4963 40.4874 25.1233H41.2874C41.2874 24.0429 40.4023 23.1801 39.3203 23.1801V23.9801ZM29.2267 31.1491C29.2267 30.5193 29.7447 30.0059 30.3771 30.0059V29.2059C29.309 29.2059 28.4267 30.0715 28.4267 31.1491H29.2267ZM29.2267 43.8394V31.1491H28.4267V43.8394H29.2267ZM30.3771 44.9662C29.7419 44.9662 29.2267 44.45 29.2267 43.8394H28.4267C28.4267 44.9034 29.3118 45.7662 30.3771 45.7662V44.9662ZM31.5442 43.8394C31.5442 44.4473 31.0317 44.9662 30.3771 44.9662V45.7662C31.4563 45.7662 32.3442 44.9061 32.3442 43.8394H31.5442ZM31.5442 31.1491V43.8394H32.3442V31.1491H31.5442ZM30.3771 30.0059C31.0289 30.0059 31.5442 30.5221 31.5442 31.1491H32.3442C32.3442 30.0687 31.4591 29.2059 30.3771 29.2059V30.0059ZM47.8096 36.1587C46.7276 36.1587 45.8425 37.0215 45.8425 38.1019H46.6425C46.6425 37.4749 47.1578 36.9587 47.8096 36.9587V36.1587ZM49.76 38.1019C49.76 37.0243 48.8777 36.1587 47.8096 36.1587V36.9587C48.4419 36.9587 48.96 37.4721 48.96 38.1019H49.76ZM49.76 44.0941V38.1019H48.96V44.0941H49.76ZM47.7929 46.0209C48.8721 46.0209 49.76 45.1608 49.76 44.0941H48.96C48.96 44.7019 48.4475 45.2209 47.7929 45.2209V46.0209ZM45.8425 44.0941C45.8425 45.1581 46.7276 46.0209 47.7929 46.0209V45.2209C47.1578 45.2209 46.6425 44.7047 46.6425 44.0941H45.8425ZM45.8425 38.1019V44.0941H46.6425V38.1019H45.8425Z'
        fill='url(#paint2_linear_1650_19736)'
        mask='url(#path-6-inside-2_1650_19736)'
      />
    </g>
    <defs>
      <filter
        id='filter0_f_1650_19736'
        x='0.825195'
        y='0.354492'
        width='53.6301'
        height='54.0903'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='6.5' result='effect1_foregroundBlur_1650_19736' />
      </filter>
      <filter
        id='filter1_b_1650_19736'
        x='4.95996'
        y='0.773926'
        width='67.189'
        height='66.5928'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feGaussianBlur in='BackgroundImageFix' stdDeviation='7.5' />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_1650_19736' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_1650_19736' result='shape' />
      </filter>
      <filter
        id='filter2_b_1650_19736'
        x='-4.70679'
        y='-5.49121'
        width='62.6509'
        height='66.6997'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feGaussianBlur in='BackgroundImageFix' stdDeviation='6.5' />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_1650_19736' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_1650_19736' result='shape' />
      </filter>
      <filter
        id='filter3_f_1650_19736'
        x='7.95996'
        y='3.77393'
        width='53.8105'
        height='56.4678'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='6' result='effect1_foregroundBlur_1650_19736' />
      </filter>
      <filter
        id='filter4_d_1650_19736'
        x='23.8267'
        y='18.5801'
        width='40.5334'
        height='42.041'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dx='5' dy='5' />
        <feGaussianBlur stdDeviation='5' />
        <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 0.447059 0 0 0 0 0.368627 0 0 0 0.5 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1650_19736' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1650_19736' result='shape' />
      </filter>
      <linearGradient
        id='paint0_linear_1650_19736'
        x1='25.8798'
        y1='20.037'
        x2='49.1397'
        y2='49.3144'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.25' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_1650_19736'
        x1='47.9042'
        y1='27.5602'
        x2='25.0288'
        y2='28.255'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop offset='1' stopColor='white' stopOpacity='0.2' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_1650_19736'
        x1='32.0952'
        y1='26.1479'
        x2='46.3319'
        y2='42.5743'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.25' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
    </defs>
  </svg>,
  'SurveySvg',
);

export default SurveySvg;
