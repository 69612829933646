import { THEMES } from '@dfl/mui-react-common';
import { grey } from '@mui/material/colors';

export const paletteLight = {
  mode: THEMES.LIGHT,
  sidebar: {
    background: 'neutral.main',
    color: '#EFF4FB',
    activeColor: 'tertiary.main', // '#fff',
    active: 'neutral.light', // '#323949',
  },
  spaceSelector: '#f3f4f9',
  primary: {
    light: '#C5CAE9',
    main: '#1C559C',
    dark: '#387acc',
  },

  secondary: {
    ...grey,
    dark: grey['900'],
    main: '#64748b',
    light: '#e2e8f0',
    contrastText: '#f5f5f5',
  },
  neutral: {
    main: '#1C2333',
    // light: '#3C4E76',
    light: '#323949',
    dark: '#553089',
  },
  tertiary: {
    main: '#fff',
    // light: '#E1E8F0',
    light: '#EFF4FB',
    contrastText: '#FDE5E4',
  },
  background: {
    default: '#F1F4F9',
    paper: '#fff',
    light: '#F0F6FE',
  },
  formLabel: '#000000de',
  link: '#1a0dab',
  success: {
    main: '#47CE81',
    light: '#EAFAF1',
    dark: '#107569',
  },
  warning: {
    main: '#FFAE34',
    light: '#FFF3E0',
  },
  error: {
    main: '#B5130F',
    light: '#f6e3e3',
    dark: '#E43030',
  },
  audit: {
    audit: '#F0950C',
    biensec: '#1C559C',
    biensecRed: '#B5130F',
  },
};
