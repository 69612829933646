export enum TEMPLATE_PERMISSIONS {
  TEMPLATE_VIEW = 'TEMPLATE_VIEW',
  TEMPLATE_WRITE = 'TEMPLATE_WRITE',
}

export enum SELECT_CONFIGURATION_PERMISSIONS {
  VIEW = 'SELECT_CONFIGURATION_VIEW',
  WRITE = 'SELECT_CONFIGURATION_WRITE',
}

export enum TEMPLATE_SECTION_PERMISSIONS {
  VIEW = 'TEMPLATE_SECTION_VIEW',
  WRITE = 'TEMPLATE_SECTION_WRITE',
}

export enum TEMPLATE_ITEM_PERMISSIONS {
  VIEW = 'TEMPLATE_ITEM_VIEW',
  WRITE = 'TEMPLATE_ITEM_WRITE',
}
