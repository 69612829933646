const TextTypeIcon = () => (
  <svg width='27' height='25' viewBox='0 0 27 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.1'
      d='M13.5339 25C20.8174 25 26.7218 19.4036 26.7218 12.5C26.7218 5.59644 20.8174 0 13.5339 0C6.25039 0 0.345947 5.59644 0.345947 12.5C0.345947 19.4036 6.25039 25 13.5339 25Z'
      fill='#1C559C'
    />
    <path
      d='M7.17358 7.83301V9.95801H10.5005V18.458H12.4964V9.95801H15.8233V7.83301H7.17358ZM19.8143 11.375H13.8264V13.5H15.8224V18.458H17.8183V13.5H19.8143V11.375Z'
      fill='#1C559C'
    />
  </svg>
);

export default TextTypeIcon;
