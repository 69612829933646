export enum PeriodicityEnum {
  MONTHLY = 'MONTHLY',
  BIMONTHLY = 'BIMONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMIANNUAL = 'BIANNUAL',
  ANNUAL = 'ANNUAL',
}
export interface IBiensecModule {
  _id?: string;
  name?: string;
  periodicity?: PeriodicityEnum;
  description?: string;
  createdAt?: Date;
  active?: boolean;
}
