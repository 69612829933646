import { memo } from 'react';
import { LanguageSelector } from '@dfl/mui-react-common';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import Stack from '@mui/material/Stack';

const AuthOptions = () => {
  return (
    <div className={'p-4 lg:mt-8 flex justify-center'}>
      <Stack direction='row' spacing={2} alignItems={'center'}>
        <LanguageSelector
          mini={false}
          compProps={{ underline: 'hover', className: 'flex items-center' }}
          icon={<LanguageOutlinedIcon fontSize={'small'} sx={{ mt: '-1px', mr: '2px' }} />}
        />
      </Stack>
    </div>
  );
};

export default memo(AuthOptions);
